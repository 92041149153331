import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

const Kyc = async (pdfBytes, individualDetails) => {
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  // Modify each page with individual details
  //////////////////////////////////KYC KYC KYC//////////////////////////////////////////////
  // if (individualDetails.firstPageDetails) {
  const firstPage = pdfDoc.getPage(0);
  firstPage.drawText(individualDetails?.company_name || "N/A", {
    x: 185,
    y: 546,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.registration_number || "N/A", {
    x: 185,
    y: 566,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.postal_address || "Postal_ Address", {
    x: 185,
    y: 526,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(
    individualDetails?.physical_address || "Physical Address",
    {
      x: 185,
      y: 506,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    }
  );

  firstPage.drawText(individualDetails?.email || "Email Address", {
    x: 185,
    y: 485,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.country_of_incorporation || "Country", {
    x: 215,
    y: 466,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.contact_name || "Contact Name", {
    x: 178,
    y: 446,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.phone_number || "ContactNo", {
    x: 168,
    y: 426,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.website || "N/A", {
    x: 285,
    y: 426,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  // checkbox
  firstPage.drawText("x", {
    x: 213,
    y: 347,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText("x", {
    x: 380,
    y: 347,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText("x", {
    x: 451.5,
    y: 347,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText("x", {
    x: 177,
    y: 320,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText("x", {
    x: 375.4,
    y: 320,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText("x", {
    x: 482,
    y: 320,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  // checkbox

  // BENEFICIAL OWNERSHIP (Contact Person)

  firstPage.drawText(individualDetails?.title || "N/A", {
    x: 110,
    y: 250,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.applicant_names || "N/A", {
    x: 210,
    y: 230,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.lastname || "N/A", {
    x: 140,
    y: 210,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.id_number || "N/A", {
    x: 338,
    y: 210,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.date_of_birth || "N/A", {
    x: 155,
    y: 190,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.email || "N/A", {
    x: 160,
    y: 170,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.postal_address || "N/A", {
    x: 170,
    y: 150,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.residential_address || "N/A", {
    x: 190,
    y: 129.5,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.tel_no || "N/A", {
    x: 119,
    y: 110,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.fax_no || "N/A", {
    x: 230,
    y: 110,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.cell_no || "N/A", {
    x: 360,
    y: 110,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.website || "N/A", {
    x: 140,
    y: 89,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.website || "N/A", {
    x: 320,
    y: 89,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });



  const secondPage = pdfDoc.getPage(1);

 
  secondPage.drawText(individualDetails?.bank_name || 'N/A', {
    x: 142,
    y: 589,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText(individualDetails?.account_name || 'N/A', {
    x: 350,
    y: 589,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText(individualDetails?.branch_name || 'N/A', {
    x: 320,
    y: 569,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText(individualDetails?.account_number || 'N/A', {
    x: 170,
    y: 569,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText(individualDetails?.branch_number || 'N/A', {
    x: 140,
    y: 548,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText(individualDetails?.source_of_funds || 'N/A', {
    x: 170,
    y: 495,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText(individualDetails?.purpose_of_funds || 'N/A', {
    x: 180,
    y: 475,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
 

  secondPage.drawText(individualDetails?.nature_of_business || 'N/A', {
    x: 185,
    y: 455,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
 

  secondPage.drawText(individualDetails?.nature_of_business || 'N/A', {
    x: 185,
    y: 435,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
////////////////////////////////////
  secondPage.drawText(individualDetails?.fullname || 'N/A', {
    x: 140,
    y: 395,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
 
  secondPage.drawText(individualDetails?.fullname || 'N/A', {
    x:330,
    y: 395,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
//////////////////////////////////////



secondPage.drawText(individualDetails?.fullname || 'N/A residential', {
  x: 170,
  y: 379,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});



secondPage.drawText(individualDetails?.nationality || 'N/A', {
  x: 130,
  y: 363,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});


secondPage.drawText(individualDetails?.ownership || 'N/A', {
  x: 330,
  y: 364,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});



secondPage.drawText(individualDetails?.nationality || 'N/A', {
  x: 130,
  y: 333,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});


secondPage.drawText(individualDetails?.ownership || 'N/A', {
  x: 330,
  y: 334,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});


//////////////////////////////////////////////////////////////////////////////////
secondPage.drawText(individualDetails?.fullname || 'N/A residential 2', {
  x: 170,
  y: 317,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});



secondPage.drawText(individualDetails?.nationality || 'N/A', {
  x: 330,
  y: 301,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});

secondPage.drawText(individualDetails?.ownership || 'N/A', {
  x: 170,
  y: 301,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});





secondPage.drawText(individualDetails?.nationality || 'N/A', {
  x: 130,
  y: 272,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});


secondPage.drawText(individualDetails?.ownership || 'N/A', {
  x: 330,
  y: 272,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});


//////////////////////////////////////////////////////////////////////////////////
secondPage.drawText(individualDetails?.fullname || 'N/A residential 2', {
  x: 170,
  y: 255,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});



secondPage.drawText(individualDetails?.nationality || 'N/A', {
  x: 330,
  y: 239,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});

secondPage.drawText(individualDetails?.ownership || 'N/A', {
  x: 140,
  y: 239,
  size: 12,
  font,
  color: rgb(0, 0, 0),
});



/////////////////////////////////////////////////////////////////////////////////////

















// secondPage.drawText(individualDetails?.ownership || 'N/A', {
//   x: 330,
//   y: 314,
//   size: 12,
//   font,
//   color: rgb(0, 0, 0),
// });




  const thirdPage = pdfDoc.getPage(2);
   // this part here is for the documents submitted
   thirdPage.drawText("x", {
    x:75,
    y:599,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  thirdPage.drawText("x", {
    x:75,
    y:582,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText("x", {
    x:75,
    y:551,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText("x", {
    x:75,
    y:535,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText("x", {
    x:75,
    y: 518,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText("x", {
    x:75,
    y:502,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText("x", {
    x:75,
    y:439,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  thirdPage.drawText("x", {
    x:75,
    y:423,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  thirdPage.drawText("x", {
    x:75,
    y:391.5,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  thirdPage.drawText("x", {
    x:75,
    y:375,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  thirdPage.drawText("x", {
    x:75,
    y:313,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText("x", {
    x:75,
    y:296,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  thirdPage.drawText("x", {
    x:75,
    y:279,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText("x", {
    x:75,
    y:248,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  thirdPage.drawText(individualDetails?.nature_of_business || 'N/A', {
    x:190,
    y:211,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });




  const forthPage = pdfDoc.getPage(3);
   // this part here is for the documents submitted
   forthPage.drawText(individualDetails?.fullnames || 'N/A', {
    x:135,
    y:464,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  forthPage.drawText(individualDetails?.signature || 'N/A', {
    x:115,
    y:438,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  forthPage.drawText(individualDetails?.signature || 'N/A', {
    x:180,
    y:412,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  forthPage.drawText(individualDetails?.date || 'N/A', {
    x:400,
    y:412,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  // }
  //////////////////////////////////KYC KYC KYC/////////////////////////////////////////////
  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default Kyc;
