import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/EstateTransfer.pdf";

const TransferEstate = async (caseDetails) => {
  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();
  let details = await caseDetails?.transactionData?.formSchema.map(
    ({ name, value }) => {
      return { name, value };
    }
  );

  const flattenedObject = details?.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, {});

  details = {
    ...flattenedObject,
  };

  const pdfDoc = await PDFDocument.load(pdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const form = pdfDoc.getForm();

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const fourthPage = pdfDoc.getPage(3);
  const fifthPage = pdfDoc.getPage(4);
  const sixthPage = pdfDoc.getPage(5);
  const seventhPage = pdfDoc.getPage(6);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);
  const eleventhPage = pdfDoc.getPage(10);
  const thirteenthPage = pdfDoc.getPage(12);

  const { width, height } = firstPage.getSize();
  let textWidth = "";
  let centerX = "";

  // Page 1
  if (1 == 1) {
    // Page 1
    const firstPage = pdfDoc.getPage(0);

    const field1 = form.createTextField("field1");
    field1.setText("Text 1");
    field1.addToPage(firstPage, {
      x: 430 - 1,
      y: 842 - (113 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field2 = form.createTextField("field2");
    field2.setText("Text 2");
    field2.addToPage(firstPage, {
      x: 237,
      y: 842 - (210 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field3 = form.createTextField("field3");
    field3.setText("Text 3");
    field3.addToPage(firstPage, {
      x: 298,
      y: 842 - (234 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field4 = form.createTextField("field4");
    field4.setText("Text 4");
    field4.addToPage(firstPage, {
      x: 295,
      y: 842 - (246 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field5 = form.createTextField("field5");
    field5.setText("Text 5");
    field5.addToPage(firstPage, {
      x: 164,
      y: 842 - (258 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field6 = form.createTextField("field6");
    field6.setText("Text 6");
    field6.addToPage(firstPage, {
      x: 232,
      y: 842 - (258 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field7 = form.createTextField("field7");
    field7.setText("Text 7");
    field7.addToPage(firstPage, {
      x: 146,
      y: 842 - (271 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field8 = form.createTextField("field8");
    field8.setText("Text 8");
    field8.addToPage(firstPage, {
      x: 338,
      y: 842 - (271 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field9 = form.createTextField("field9");
    field9.setText("Text 9");
    field9.addToPage(firstPage, {
      x: 415,
      y: 842 - (271 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field10 = form.createTextField("field10");
    field10.setText("Text 10");
    field10.addToPage(firstPage, {
      x: 463,
      y: 842 - (271 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field11 = form.createTextField("field11");
    field11.setText("Text 11");
    field11.addToPage(firstPage, {
      x: 288,
      y: 842 - (283 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field12 = form.createTextField("field12");
    field12.setText("Text 12");
    field12.addToPage(firstPage, {
      x: 364,
      y: 842 - (283 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field13 = form.createTextField("field13");
    field13.setText("Text 13");
    field13.addToPage(firstPage, {
      x: 436,
      y: 842 - (283 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field14 = form.createTextField("field14");
    field14.setText("Text 14");
    field14.addToPage(firstPage, {
      x: 230,
      y: 842 - (331 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field15 = form.createTextField("field15");
    field15.setText("Text 15");
    field15.addToPage(firstPage, {
      x: 126,
      y: 842 - (392 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field16 = form.createTextField("field16");
    field16.setText("Text 16");
    field16.addToPage(firstPage, {
      x: 199,
      y: 842 - (392 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field17 = form.createTextField("field17");
    field17.setText("Text 17");
    field17.addToPage(firstPage, {
      x: 246,
      y: 842 - (392 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field18 = form.createTextField("field18");
    field18.setText("Text 18");
    field18.addToPage(firstPage, {
      x: 238,
      y: 842 - (416 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field19 = form.createTextField("field19");
    field19.setText("Text 19");
    field19.addToPage(firstPage, {
      x: 258,
      y: 842 - (429 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field20 = form.createTextField("field20");
    field20.setText("Text 20");
    field20.addToPage(firstPage, {
      x: 324,
      y: 842 - (429 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field21 = form.createTextField("field21");
    field21.setText("Text 21");
    field21.addToPage(firstPage, {
      x: 375,
      y: 842 - (429 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field22 = form.createTextField("field22");
    field22.setText("Text 22");
    field22.addToPage(firstPage, {
      x: 248,
      y: 842 - (453 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field23 = form.createTextField("field23");
    field23.setText("Text 23");
    field23.addToPage(firstPage, {
      x: 258,
      y: 842 - (465 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field24 = form.createTextField("field24");
    field24.setText("Text 24");
    field24.addToPage(firstPage, {
      x: 324,
      y: 842 - (465 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field25 = form.createTextField("field25");
    field25.setText("Text 25");
    field25.addToPage(firstPage, {
      x: 374,
      y: 842 - (465 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field26 = form.createTextField("field26");
    field26.setText("Text 26");
    field26.addToPage(firstPage, {
      x: 298,
      y: 842 - (538 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field27 = form.createTextField("field27");
    field27.setText("Text 27");
    field27.addToPage(firstPage, {
      x: 304,
      y: 842 - (624 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field28 = form.createTextField("field28");
    field28.setText("Text 28");
    field28.addToPage(firstPage, {
      x: 413,
      y: 842 - (624 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field29 = form.createTextField("field29");
    field29.setText("Text 29");
    field29.addToPage(firstPage, {
      x: 497,
      y: 842 - (624 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field30 = form.createTextField("field30");
    field30.setText("Text 30");
    field30.addToPage(firstPage, {
      x: 192,
      y: 842 - (635 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field31 = form.createTextField("field31");
    field31.setText("Text 31");
    field31.addToPage(firstPage, {
      x: 309,
      y: 842 - (635 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field32 = form.createTextField("field32");
    field32.setText("Text 32");
    field32.addToPage(firstPage, {
      x: 502,
      y: 842 - (659 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field33 = form.createTextField("field33");
    field33.setText("Text 33");
    field33.addToPage(firstPage, {
      x: 71,
      y: 842 - (684 + 7),
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }

  //page 2
  if (1 == 1) {
    const field34 = form.createTextField("field34");
    field34.setText("Text 1");
    field34.addToPage(secondPage, {
      x: 255.22,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field35 = form.createTextField("field35");
    field35.setText("Text 2");
    field35.addToPage(secondPage, {
      x: 342.12,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field36 = form.createTextField("field36");
    field36.setText("Text 3");
    field36.addToPage(secondPage, {
      x: 391.34,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field37 = form.createTextField("field37");
    field37.setText("Text 4");
    field37.addToPage(secondPage, {
      x: 387.74,
      y: 842 - 200.85656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field38 = form.createTextField("field38");
    field38.setText("Text 5");
    field38.addToPage(secondPage, {
      x: 429.53,
      y: 842 - 200.85656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field39 = form.createTextField("field39");
    field39.setText("Text 6");
    field39.addToPage(secondPage, {
      x: 471.31,
      y: 842 - 200.85656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }

  //page 3
  if (1 == 1) {
    const field52 = form.createTextField("field52");
    field52.setText("Text 1");
    field52.addToPage(thirdPage, {
      x: 229.3,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field53 = form.createTextField("field53");
    field53.setText("Text 2");
    field53.addToPage(thirdPage, {
      x: 286.44,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field54 = form.createTextField("field54");
    field54.setText("Text 3");
    field54.addToPage(thirdPage, {
      x: 335.64,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field55 = form.createTextField("field55");
    field55.setText("Text 4");
    field55.addToPage(thirdPage, {
      x: 285.96,
      y: 842 - 139.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field56 = form.createTextField("field56");
    field56.setText("Text 5");
    field56.addToPage(thirdPage, {
      x: 335.16,
      y: 842 - 139.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field57 = form.createTextField("field57");
    field57.setText("Text 6");
    field57.addToPage(thirdPage, {
      x: 392.06,
      y: 842 - 139.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field58 = form.createTextField("field58");
    field58.setText("Text 7");
    field58.addToPage(thirdPage, {
      x: 286.44,
      y: 842 - 152.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field59 = form.createTextField("field59");
    field59.setText("Text 8");
    field59.addToPage(thirdPage, {
      x: 335.64,
      y: 842 - 152.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field60 = form.createTextField("field60");
    field60.setText("Text 9");
    field60.addToPage(thirdPage, {
      x: 381.02,
      y: 842 - 152.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field61 = form.createTextField("field61");
    field61.setText("Text 10");
    field61.addToPage(thirdPage, {
      x: 170.95,
      y: 842 - 164.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field62 = form.createTextField("field62");
    field62.setText("Text 11");
    field62.addToPage(thirdPage, {
      x: 239.86,
      y: 842 - 164.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field63 = form.createTextField("field63");
    field63.setText("Text 12");
    field63.addToPage(thirdPage, {
      x: 273.7,
      y: 842 - 164.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field64 = form.createTextField("field64");
    field64.setText("Text 13");
    field64.addToPage(thirdPage, {
      x: 137.33,
      y: 842 - 176.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field65 = form.createTextField("field65");
    field65.setText("Text 14");
    field65.addToPage(thirdPage, {
      x: 322.44,
      y: 842 - 176.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field66 = form.createTextField("field66");
    field66.setText("Text 15");
    field66.addToPage(thirdPage, {
      x: 398.78,
      y: 842 - 176.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field67 = form.createTextField("field67");
    field67.setText("Text 16");
    field67.addToPage(thirdPage, {
      x: 440.3,
      y: 842 - 176.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field68 = form.createTextField("field68");
    field68.setText("Text 17");
    field68.addToPage(thirdPage, {
      x: 293.64,
      y: 842 - 188.61656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field69 = form.createTextField("field69");
    field69.setText("Text 18");
    field69.addToPage(thirdPage, {
      x: 373.34,
      y: 842 - 188.61656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field70 = form.createTextField("field70");
    field70.setText("Text 19");
    field70.addToPage(thirdPage, {
      x: 414.65,
      y: 842 - 188.61656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field71 = form.createTextField("field71");
    field71.setText("Text 20");
    field71.addToPage(thirdPage, {
      x: 244.66,
      y: 842 - 237.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field72 = form.createTextField("field72");
    field72.setText("Text 21");
    field72.addToPage(thirdPage, {
      x: 278.74,
      y: 842 - 237.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field73 = form.createTextField("field73");
    field73.setText("Text 22");
    field73.addToPage(thirdPage, {
      x: 312.84,
      y: 842 - 237.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field74 = form.createTextField("field74");
    field74.setText("Text 23");
    field74.addToPage(thirdPage, {
      x: 257.38,
      y: 842 - 249.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field75 = form.createTextField("field75");
    field75.setText("Text 24");
    field75.addToPage(thirdPage, {
      x: 322.92,
      y: 842 - 249.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field76 = form.createTextField("field76");
    field76.setText("Text 25");
    field76.addToPage(thirdPage, {
      x: 360.79,
      y: 842 - 249.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field77 = form.createTextField("field77");
    field77.setText("Text 26");
    field77.addToPage(thirdPage, {
      x: 242.5,
      y: 842 - 273.59656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field78 = form.createTextField("field78");
    field78.setText("Text 27");
    field78.addToPage(thirdPage, {
      x: 307.32,
      y: 842 - 273.59656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field79 = form.createTextField("field79");
    field79.setText("Text 28");
    field79.addToPage(thirdPage, {
      x: 264.34,
      y: 842 - 285.83656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field80 = form.createTextField("field80");
    field80.setText("Text 29");
    field80.addToPage(thirdPage, {
      x: 329.88,
      y: 842 - 285.83656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field81 = form.createTextField("field81");
    field81.setText("Text 30");
    field81.addToPage(thirdPage, {
      x: 296.76,
      y: 842 - 346.58656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field82 = form.createTextField("field82");
    field82.setText("Text 31");
    field82.addToPage(thirdPage, {
      x: 289.56,
      y: 842 - 431.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field83 = form.createTextField("field83");
    field83.setText("Text 32");
    field83.addToPage(thirdPage, {
      x: 385.58,
      y: 842 - 431.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field84 = form.createTextField("field84");
    field84.setText("Text 33");
    field84.addToPage(thirdPage, {
      x: 459.77,
      y: 842 - 431.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field85 = form.createTextField("field85");
    field85.setText("Text 34");
    field85.addToPage(thirdPage, {
      x: 491.4,
      y: 842 - 431.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field86 = form.createTextField("field86");
    field86.setText("Text 35");
    field86.addToPage(thirdPage, {
      x: 271.78,
      y: 842 - 443.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field87 = form.createTextField("field87");
    field87.setText("Text 36");
    field87.addToPage(thirdPage, {
      x: 313.56,
      y: 842 - 443.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field88 = form.createTextField("field88");
    field88.setText("Text 37");
    field88.addToPage(thirdPage, {
      x: 448.01,
      y: 842 - 468.28656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field89 = form.createTextField("field89");
    field89.setText("Text 38");
    field89.addToPage(thirdPage, {
      x: 70.824,
      y: 842 - 480.28656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field90 = form.createTextField("field90");
    field90.setText("Text 39");
    field90.addToPage(thirdPage, {
      x: 115.70016,
      y: 842 - 480.28656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }

  //page 4
  if (1 == 1) {
    const field91 = form.createTextField("field91");
    field91.setText("Text 1");
    field91.addToPage(fourthPage, {
      x: 444.89,
      y: 842 - 407.32656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field92 = form.createTextField("field92");
    field92.setText("Text 2");
    field92.addToPage(fourthPage, {
      x: 486.65144,
      y: 842 - 407.32656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field93 = form.createTextField("field93");
    field93.setText("Text 3");
    field93.addToPage(fourthPage, {
      x: 337.8,
      y: 842 - 468.28656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field94 = form.createTextField("field94");
    field94.setText("Text 4");
    field94.addToPage(fourthPage, {
      x: 432.41,
      y: 842 - 468.28656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 5
  if (1 == 1) {
    const field95 = form.createTextField("field95");
    field95.setText("Text 1");
    field95.addToPage(fifthPage, {
      x: 244.66,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field96 = form.createTextField("field96");
    field96.setText("Text 2");
    field96.addToPage(fifthPage, {
      x: 278.74,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field97 = form.createTextField("field97");
    field97.setText("Text 3");
    field97.addToPage(fifthPage, {
      x: 312.82,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field98 = form.createTextField("field98");
    field98.setText("Text 4");
    field98.addToPage(fifthPage, {
      x: 257.28,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field99 = form.createTextField("field99");
    field99.setText("Text 5");
    field99.addToPage(fifthPage, {
      x: 322.73,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field100 = form.createTextField("field100");
    field100.setText("Text 6");
    field100.addToPage(fifthPage, {
      x: 360.6,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field101 = form.createTextField("field101");
    field101.setText("Text 7");
    field101.addToPage(fifthPage, {
      x: 242.5,
      y: 842 - 152.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field102 = form.createTextField("field102");
    field102.setText("Text 8");
    field102.addToPage(fifthPage, {
      x: 307.32,
      y: 842 - 152.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field103 = form.createTextField("field103");
    field103.setText("Text 9");
    field103.addToPage(fifthPage, {
      x: 264.24,
      y: 842 - 164.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field104 = form.createTextField("field104");
    field104.setText("Text 10");
    field104.addToPage(fifthPage, {
      x: 329.69,
      y: 842 - 164.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field105 = form.createTextField("field105");
    field105.setText("Text 11");
    field105.addToPage(fifthPage, {
      x: 311.16,
      y: 842 - 237.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field106 = form.createTextField("field106");
    field106.setText("Text 12");
    field106.addToPage(fifthPage, {
      x: 296.76,
      y: 842 - 298.07656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field107 = form.createTextField("field107");
    field107.setText("Text 13");
    field107.addToPage(fifthPage, {
      x: 289.56,
      y: 842 - 383.06656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field108 = form.createTextField("field108");
    field108.setText("Text 14");
    field108.addToPage(fifthPage, {
      x: 385.58,
      y: 842 - 383.06656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field109 = form.createTextField("field109");
    field109.setText("Text 15");
    field109.addToPage(fifthPage, {
      x: 459.77,
      y: 842 - 383.06656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field110 = form.createTextField("field110");
    field110.setText("Text 16");
    field110.addToPage(fifthPage, {
      x: 491.4,
      y: 842 - 383.06656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field111 = form.createTextField("field111");
    field111.setText("Text 17");
    field111.addToPage(fifthPage, {
      x: 271.78,
      y: 842 - 395.30656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field112 = form.createTextField("field112");
    field112.setText("Text 18");
    field112.addToPage(fifthPage, {
      x: 313.56,
      y: 842 - 395.30656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field113 = form.createTextField("field113");
    field113.setText("Text 19");
    field113.addToPage(fifthPage, {
      x: 436.01,
      y: 842 - 431.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field114 = form.createTextField("field114");
    field114.setText("Text 20");
    field114.addToPage(fifthPage, {
      x: 70.824,
      y: 842 - 443.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field115 = form.createTextField("field115");
    field115.setText("Text 21");
    field115.addToPage(fifthPage, {
      x: 129.89,
      y: 842 - 443.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 6
  if (1 == 1) {
    const field116 = form.createTextField("field116");
    field116.setText("Text 1");
    field116.addToPage(sixthPage, {
      x: 471.07,
      y: 842 - 261.59656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field117 = form.createTextField("field117");
    field117.setText("Text 2");
    field117.addToPage(sixthPage, {
      x: 341.16,
      y: 842 - 322.34656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field118 = form.createTextField("field118");
    field118.setText("Text 3");
    field118.addToPage(sixthPage, {
      x: 430.01,
      y: 842 - 322.34656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 7
  if (1 == 1) {
    const field119 = form.createTextField("field119");
    field119.setText("Text 1");
    field119.addToPage(seventhPage, {
      x: 236.98,
      y: 842 - 249.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field120 = form.createTextField("field120");
    field120.setText("Text 2");
    field120.addToPage(seventhPage, {
      x: 281.61424,
      y: 842 - 249.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field121 = form.createTextField("field121");
    field121.setText("Text 3");
    field121.addToPage(seventhPage, {
      x: 325.2808,
      y: 842 - 249.35656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field122 = form.createTextField("field122");
    field122.setText("Text 4");
    field122.addToPage(seventhPage, {
      x: 214.39,
      y: 842 - 346.58656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field123 = form.createTextField("field123");
    field123.setText("Text 5");
    field123.addToPage(seventhPage, {
      x: 297.0,
      y: 842 - 346.58656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field124 = form.createTextField("field124");
    field124.setText("Text 6");
    field124.addToPage(seventhPage, {
      x: 344.6784,
      y: 842 - 346.58656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field125 = form.createTextField("field125");
    field125.setText("Text 7");
    field125.addToPage(seventhPage, {
      x: 342.36,
      y: 842 - 383.06656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field126 = form.createTextField("field126");
    field126.setText("Text 8");
    field126.addToPage(seventhPage, {
      x: 385.79472,
      y: 842 - 383.06656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field127 = form.createTextField("field127");
    field127.setText("Text 9");
    field127.addToPage(seventhPage, {
      x: 491.47,
      y: 842 - 553.26656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field128 = form.createTextField("field128");
    field128.setText("Text 10");
    field128.addToPage(seventhPage, {
      x: 119.81,
      y: 842 - 565.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field129 = form.createTextField("field129");
    field129.setText("Text 11");
    field129.addToPage(seventhPage, {
      x: 171.67,
      y: 842 - 565.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 8
  if (1 == 1) {
    const field130 = form.createTextField("field130");
    field130.setText("Text 1");
    field130.addToPage(eighthPage, {
      x: 235.54,
      y: 842 - 589.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field131 = form.createTextField("field131");
    field131.setText("Text 2");
    field131.addToPage(eighthPage, {
      x: 277.78,
      y: 842 - 589.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field132 = form.createTextField("field132");
    field132.setText("Text 3");
    field132.addToPage(eighthPage, {
      x: 320.28,
      y: 842 - 589.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field133 = form.createTextField("field133");
    field133.setText("Text 4");
    field133.addToPage(eighthPage, {
      x: 193.03,
      y: 842 - 650.49656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field134 = form.createTextField("field134");
    field134.setText("Text 5");
    field134.addToPage(eighthPage, {
      x: 267.94,
      y: 842 - 650.49656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field135 = form.createTextField("field135");
    field135.setText("Text 6");
    field135.addToPage(eighthPage, {
      x: 304.92,
      y: 842 - 650.49656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field136 = form.createTextField("field136");
    field136.setText("Text 7");
    field136.addToPage(eighthPage, {
      x: 236.74,
      y: 842 - 674.99656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field137 = form.createTextField("field137");
    field137.setText("Text 8");
    field137.addToPage(eighthPage, {
      x: 286.18,
      y: 842 - 674.99656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field138 = form.createTextField("field138");
    field138.setText("Text 9");
    field138.addToPage(eighthPage, {
      x: 327.7,
      y: 842 - 674.99656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field139 = form.createTextField("field139");
    field139.setText("Text 10");
    field139.addToPage(eighthPage, {
      x: 297.48,
      y: 842 - 699.23656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field140 = form.createTextField("field140");
    field140.setText("Text 11");
    field140.addToPage(eighthPage, {
      x: 339.24,
      y: 842 - 699.23656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field141 = form.createTextField("field141");
    field141.setText("Text 12");
    field141.addToPage(eighthPage, {
      x: 388.44,
      y: 842 - 699.23656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field142 = form.createTextField("field142");
    field142.setText("Text 13");
    field142.addToPage(eighthPage, {
      x: 294.12,
      y: 842 - 711.23656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field143 = form.createTextField("field143");
    field143.setText("Text 14");
    field143.addToPage(eighthPage, {
      x: 335.64,
      y: 842 - 711.23656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field144 = form.createTextField("field144");
    field144.setText("Text 15");
    field144.addToPage(eighthPage, {
      x: 380.99,
      y: 842 - 711.23656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field145 = form.createTextField("field145");
    field145.setText("Text 16");
    field145.addToPage(eighthPage, {
      x: 162.79,
      y: 842 - 723.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field146 = form.createTextField("field146");
    field146.setText("Text 17");
    field146.addToPage(eighthPage, {
      x: 231.7,
      y: 842 - 723.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field147 = form.createTextField("field147");
    field147.setText("Text 18");
    field147.addToPage(eighthPage, {
      x: 273.22,
      y: 842 - 723.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field148 = form.createTextField("field148");
    field148.setText("Text 19");
    field148.addToPage(eighthPage, {
      x: 145.47,
      y: 842 - 735.71656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field149 = form.createTextField("field149");
    field149.setText("Text 20");
    field149.addToPage(eighthPage, {
      x: 337.32,
      y: 842 - 735.71656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field150 = form.createTextField("field150");
    field150.setText("Text 21");
    field150.addToPage(eighthPage, {
      x: 413.93,
      y: 842 - 735.71656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field151 = form.createTextField("field151");
    field151.setText("Text 22");
    field151.addToPage(eighthPage, {
      x: 300.84,
      y: 842 - 747.71856,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field152 = form.createTextField("field152");
    field152.setText("Text 23");
    field152.addToPage(eighthPage, {
      x: 376.7,
      y: 842 - 747.71856,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field153 = form.createTextField("field153");
    field153.setText("Text 24");
    field153.addToPage(eighthPage, {
      x: 428.24,
      y: 842 - 747.71856,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 9
  if (1 == 1) {
    const field154 = form.createTextField("field154");
    field154.setText("Text 1");
    field154.addToPage(ninthPage, {
      x: 164.71,
      y: 842 - 103.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field155 = form.createTextField("field155");
    field155.setText("Text 2");
    field155.addToPage(ninthPage, {
      x: 202.87,
      y: 842 - 103.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field156 = form.createTextField("field156");
    field156.setText("Text 3");
    field156.addToPage(ninthPage, {
      x: 254.74,
      y: 842 - 103.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field157 = form.createTextField("field157");
    field157.setText("Text 4");
    field157.addToPage(ninthPage, {
      x: 70.824,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field158 = form.createTextField("field158");
    field158.setText("Text 5");
    field158.addToPage(ninthPage, {
      x: 139.25,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field159 = form.createTextField("field159");
    field159.setText("Text 6");
    field159.addToPage(ninthPage, {
      x: 196.15,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field160 = form.createTextField("field160");
    field160.setText("Text 7");
    field160.addToPage(ninthPage, {
      x: 197.35,
      y: 842 - 139.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field161 = form.createTextField("field161");
    field161.setText("Text 8");
    field161.addToPage(ninthPage, {
      x: 243.7,
      y: 842 - 139.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field162 = form.createTextField("field162");
    field162.setText("Text 9");
    field162.addToPage(ninthPage, {
      x: 296.52,
      y: 842 - 139.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field163 = form.createTextField("field163");
    field163.setText("Text 10");
    field163.addToPage(ninthPage, {
      x: 453.05,
      y: 842 - 139.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field164 = form.createTextField("field164");
    field164.setText("Text 11");
    field164.addToPage(ninthPage, {
      x: 70.824,
      y: 842 - 152.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field165 = form.createTextField("field165");
    field165.setText("Text 12");
    field165.addToPage(ninthPage, {
      x: 198.79,
      y: 842 - 188.61656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field166 = form.createTextField("field166");
    field166.setText("Text 13");
    field166.addToPage(ninthPage, {
      x: 245.38,
      y: 842 - 188.61656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field167 = form.createTextField("field167");
    field167.setText("Text 14");
    field167.addToPage(ninthPage, {
      x: 285.24,
      y: 842 - 188.61656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field168 = form.createTextField("field168");
    field168.setText("Text 15");
    field168.addToPage(ninthPage, {
      x: 364.46,
      y: 842 - 225.11656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field169 = form.createTextField("field169");
    field169.setText("Text 16");
    field169.addToPage(ninthPage, {
      x: 451.85,
      y: 842 - 225.11656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field170 = form.createTextField("field170");
    field170.setText("Text 17");
    field170.addToPage(ninthPage, {
      x: 490.51,
      y: 842 - 225.11656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field171 = form.createTextField("field171");
    field171.setText("Text 18");
    field171.addToPage(ninthPage, {
      x: 244.66,
      y: 842 - 273.59656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field172 = form.createTextField("field172");
    field172.setText("Text 19");
    field172.addToPage(ninthPage, {
      x: 278.74,
      y: 842 - 273.59656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field173 = form.createTextField("field173");
    field173.setText("Text 20");
    field173.addToPage(ninthPage, {
      x: 312.82,
      y: 842 - 273.59656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field174 = form.createTextField("field174");
    field174.setText("Text 21");
    field174.addToPage(ninthPage, {
      x: 257.28,
      y: 842 - 285.83656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field175 = form.createTextField("field175");
    field175.setText("Text 22");
    field175.addToPage(ninthPage, {
      x: 322.73,
      y: 842 - 285.83656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field176 = form.createTextField("field176");
    field176.setText("Text 23");
    field176.addToPage(ninthPage, {
      x: 360.6,
      y: 842 - 285.83656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field177 = form.createTextField("field177");
    field177.setText("Text 24");
    field177.addToPage(ninthPage, {
      x: 242.5,
      y: 842 - 310.07656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field178 = form.createTextField("field178");
    field178.setText("Text 25");
    field178.addToPage(ninthPage, {
      x: 307.32,
      y: 842 - 310.07656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field179 = form.createTextField("field179");
    field179.setText("Text 26");
    field179.addToPage(ninthPage, {
      x: 264.24,
      y: 842 - 322.34656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field180 = form.createTextField("field180");
    field180.setText("Text 27");
    field180.addToPage(ninthPage, {
      x: 329.69,
      y: 842 - 322.34656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field181 = form.createTextField("field181");
    field181.setText("Text 28");
    field181.addToPage(ninthPage, {
      x: 332.76,
      y: 842 - 395.30656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field182 = form.createTextField("field182");
    field182.setText("Text 29");
    field182.addToPage(ninthPage, {
      x: 381.26,
      y: 842 - 395.30656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field183 = form.createTextField("field183");
    field183.setText("Text 30");
    field183.addToPage(ninthPage, {
      x: 214.87,
      y: 842 - 443.80656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field184 = form.createTextField("field184");
    field184.setText("Text 31");
    field184.addToPage(ninthPage, {
      x: 372.86,
      y: 842 - 492.54656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field185 = form.createTextField("field185");
    field185.setText("Text 32");
    field185.addToPage(ninthPage, {
      x: 260.26,
      y: 842 - 504.78656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field186 = form.createTextField("field186");
    field186.setText("Text 33");
    field186.addToPage(ninthPage, {
      x: 341.16,
      y: 842 - 504.78656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field187 = form.createTextField("field187");
    field187.setText("Text 34");
    field187.addToPage(ninthPage, {
      x: 387.02,
      y: 842 - 504.78656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field188 = form.createTextField("field188");
    field188.setText("Text 35");
    field188.addToPage(ninthPage, {
      x: 425.14,
      y: 842 - 504.78656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field189 = form.createTextField("field189");
    field189.setText("Text 36");
    field189.addToPage(ninthPage, {
      x: 456.65,
      y: 842 - 516.78656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field190 = form.createTextField("field190");
    field190.setText("Text 37");
    field190.addToPage(ninthPage, {
      x: 212.71,
      y: 842 - 529.02656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field191 = form.createTextField("field191");
    field191.setText("Text 38");
    field191.addToPage(ninthPage, {
      x: 244.85,
      y: 842 - 529.02656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field192 = form.createTextField("field192");
    field192.setText("Text 39");
    field192.addToPage(ninthPage, {
      x: 478.51,
      y: 842 - 553.26656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field193 = form.createTextField("field193");
    field193.setText("Text 40");
    field193.addToPage(ninthPage, {
      x: 245.62,
      y: 842 - 565.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field194 = form.createTextField("field194");
    field194.setText("Text 41");
    field194.addToPage(ninthPage, {
      x: 317.64,
      y: 842 - 565.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field195 = form.createTextField("field195");
    field195.setText("Text 42");
    field195.addToPage(ninthPage, {
      x: 357.69,
      y: 842 - 565.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field196 = form.createTextField("field196");
    field196.setText("Text 43");
    field196.addToPage(ninthPage, {
      x: 419.21,
      y: 842 - 577.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field197 = form.createTextField("field197");
    field197.setText("Text 44");
    field197.addToPage(ninthPage, {
      x: 501.07,
      y: 842 - 577.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field198 = form.createTextField("field198");
    field198.setText("Text 45");
    field198.addToPage(ninthPage, {
      x: 255.22,
      y: 842 - 589.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field199 = form.createTextField("field199");
    field199.setText("Text 46");
    field199.addToPage(ninthPage, {
      x: 291.88,
      y: 842 - 589.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field200 = form.createTextField("field200");
    field200.setText("Text 47");
    field200.addToPage(ninthPage, {
      x: 437.45,
      y: 842 - 589.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field201 = form.createTextField("field201");
    field201.setText("Text 48");
    field201.addToPage(ninthPage, {
      x: 498.15,
      y: 842 - 589.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field202 = form.createTextField("field202");
    field202.setText("Text 49");
    field202.addToPage(ninthPage, {
      x: 212.71,
      y: 842 - 602.01656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 10
  if (1 == 1) {
    const field203 = form.createTextField("field203");
    field203.setText("Text 1");
    field203.addToPage(tenthPage, {
      x: 70.824,
      y: 842 - 668.75656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field204 = form.createTextField("field204");
    field204.setText("Text 2");
    field204.addToPage(tenthPage, {
      x: 102.77,
      y: 842 - 668.75656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field205 = form.createTextField("field205");
    field205.setText("Text 3");
    field205.addToPage(tenthPage, {
      x: 175.03,
      y: 842 - 668.75656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field206 = form.createTextField("field206");
    field206.setText("Text 4");
    field206.addToPage(tenthPage, {
      x: 219.7,
      y: 842 - 680.99656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field207 = form.createTextField("field207");
    field207.setText("Text 5");
    field207.addToPage(tenthPage, {
      x: 264.58,
      y: 842 - 680.99656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field208 = form.createTextField("field208");
    field208.setText("Text 6");
    field208.addToPage(tenthPage, {
      x: 315.72,
      y: 842 - 680.99656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field209 = form.createTextField("field209");
    field209.setText("Text 7");
    field209.addToPage(tenthPage, {
      x: 244.66,
      y: 842 - 717.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field210 = form.createTextField("field210");
    field210.setText("Text 8");
    field210.addToPage(tenthPage, {
      x: 278.74,
      y: 842 - 717.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field211 = form.createTextField("field211");
    field211.setText("Text 9");
    field211.addToPage(tenthPage, {
      x: 312.82,
      y: 842 - 717.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field212 = form.createTextField("field212");
    field212.setText("Text 10");
    field212.addToPage(tenthPage, {
      x: 257.28,
      y: 842 - 729.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field213 = form.createTextField("field213");
    field213.setText("Text 11");
    field213.addToPage(tenthPage, {
      x: 322.73,
      y: 842 - 729.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field214 = form.createTextField("field214");
    field214.setText("Text 12");
    field214.addToPage(tenthPage, {
      x: 360.6,
      y: 842 - 729.47656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field215 = form.createTextField("field215");
    field215.setText("Text 13");
    field215.addToPage(tenthPage, {
      x: 242.5,
      y: 842 - 753.95856,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field216 = form.createTextField("field216");
    field216.setText("Text 14");
    field216.addToPage(tenthPage, {
      x: 307.32,
      y: 842 - 753.95856,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field217 = form.createTextField("field217");
    field217.setText("Text 15");
    field217.addToPage(tenthPage, {
      x: 264.3,
      y: 842 - 765.98256,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field218 = form.createTextField("field218");
    field218.setText("Text 16");
    field218.addToPage(tenthPage, {
      x: 329.75,
      y: 842 - 765.98256,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 11
  if (1 == 1) {
    const field219 = form.createTextField("field219");
    field219.setText("Text 1");
    field219.addToPage(eleventhPage, {
      x: 491.23,
      y: 842 - 91.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field220 = form.createTextField("field220");
    field220.setText("Text 2");
    field220.addToPage(eleventhPage, {
      x: 70.824,
      y: 842 - 103.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field221 = form.createTextField("field221");
    field221.setText("Text 3");
    field221.addToPage(eleventhPage, {
      x: 116.21,
      y: 842 - 103.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field222 = form.createTextField("field222");
    field222.setText("Text 4");
    field222.addToPage(eleventhPage, {
      x: 284.28,
      y: 842 - 103.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field223 = form.createTextField("field223");
    field223.setText("Text 5");
    field223.addToPage(eleventhPage, {
      x: 446.57,
      y: 842 - 103.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field224 = form.createTextField("field224");
    field224.setText("Text 6");
    field224.addToPage(eleventhPage, {
      x: 70.824,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field225 = form.createTextField("field225");
    field225.setText("Text 7");
    field225.addToPage(eleventhPage, {
      x: 109.73,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field226 = form.createTextField("field226");
    field226.setText("Text 8");
    field226.addToPage(eleventhPage, {
      x: 337.32,
      y: 842 - 115.62656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field227 = form.createTextField("field227");
    field227.setText("Text 9");
    field227.addToPage(eleventhPage, {
      x: 70.824,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field228 = form.createTextField("field228");
    field228.setText("Text 10");
    field228.addToPage(eleventhPage, {
      x: 149.33,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field229 = form.createTextField("field229");
    field229.setText("Text 11");
    field229.addToPage(eleventhPage, {
      x: 186.28,
      y: 842 - 127.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 13
  if (1 == 1) {
    const field230 = form.createTextField("field230");
    field230.setText("Text 1");
    field230.addToPage(thirteenthPage, {
      x: 362.06,
      y: 842 - 176.37656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field231 = form.createTextField("field231");
    field231.setText("Text 2");
    field231.addToPage(thirteenthPage, {
      x: 312.12,
      y: 842 - 225.11656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field232 = form.createTextField("field232");
    field232.setText("Text 3");
    field232.addToPage(thirteenthPage, {
      x: 174.31,
      y: 842 - 346.58656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field233 = form.createTextField("field233");
    field233.setText("Text 4");
    field233.addToPage(thirteenthPage, {
      x: 226.66,
      y: 842 - 407.32656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }

  /*
  if(1==1){
  const undersigned = form.createTextField("undersigned");
  undersigned.setText("Name of undersigned");
  undersigned.addToPage(firstPage, {
    x: 240,
    y: 628,
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const day_ = form.createTextField("day_");
  day_.setText("14");
  day_.addToPage(firstPage, {
    x: 210,
    y: 612,
    height: 17,
    width: 33,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const month_ = form.createTextField("month_");
  month_.setText("Decemeber");
  month_.addToPage(firstPage, {
    x: 269,
    y: 612,
    height: 17,
    width: 63,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const year_ = form.createTextField("year_");
  year_.setText("1994");
  year_.addToPage(firstPage, {
    x: 335,
    y: 612,
    height: 17,
    width: 46,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field5 = form.createTextField("field5");
  field5.setText("Samuel Modise");
  field5.addToPage(firstPage, {
    x: 230,
    y: 550, // Coordinate (282, 234)
    size: 12,
    font,
    color: rgb(0, 0, 0),
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field6_value = "Malebogo (Purchaser)";
  textWidth = font.widthOfTextAtSize(field6_value, 13);
  centerX = (width - textWidth) / 2 - 50;
  const field6 = form.createTextField("field6");
  field6.setText(field6_value);
  field6.addToPage(firstPage, {
    x: 230,
    y: 470, // Coordinate (282, 234)
    size: 13,
    font,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field7 = form.createTextField("field7");
  field7.setText("20");
  field7.addToPage(firstPage, {
    x: 204,
    y: 455, // Coordinate (282, 234)
    size: 12,
    font,
    width: 58,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field8 = form.createTextField("field8");
  field8.setText("December");
  field8.addToPage(firstPage, {
    x: 300,
    y: 455, // Coordinate (282, 234)
    size: 12,
    font,
    width: 70,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field9 = form.createTextField("field9");
  field9.setText("1990");
  field9.addToPage(firstPage, {
    x: 372,
    y: 455, // Coordinate (282, 234)
    size: 12,
    font,
    width: 47,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field10_value = "1990";
  textWidth = font.widthOfTextAtSize(field10_value, 11);
  centerX = (width - textWidth) / 2 - 50;
  const field10 = form.createTextField("field10");
  field10.setText(field10_value);
  field10.addToPage(firstPage, {
    x: centerX,
    y: 430, // Coordinate (282, 234)
    size: 11,
    font,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field11 = form.createTextField("field11");
  field11.setText("lot");
  field11.addToPage(firstPage, {
    x: 347,
    y: 321, // Coordinate (282, 234)
    size: 12,
    font,
    width: 95,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field12 = form.createTextField("field12");
  field12.setText("12345 Gaborone;");
  field12.addToPage(firstPage, {
    x: 213,
    y: 308, // Coordinate (282, 234)
    size: 13,
    font,
    width: 100,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field13 = form.createTextField("field13");
  field13.setText("lot");
  field13.addToPage(firstPage, {
    x: 213,
    y: 260, // Coordinate (282, 234)
    size: 12,
    font,
    width: 313,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field14 = form.createTextField("field14");
  field14.setText("transfer number");
  field14.addToPage(firstPage, {
    x: 356,
    y: 236, // Coordinate (282, 234)
    size: 12,
    font,
    width: 80,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field15 = form.createTextField("field15");
  field15.setText("16");
  field15.addToPage(firstPage, {
    x: 468,
    y: 236, // Coordinate (282, 234)
    size: 12,
    font,
    width: 24,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field16 = form.createTextField("field16");
  field16.setText("Decemeber 2024");
  field16.addToPage(firstPage, {
    x: 214,
    y: 223.48, // Coordinate (282, 234)
    size: 12,
    font,
    width: 66,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field17 = form.createTextField("field17");
  field17.setText("Masego Marang Modise");
  field17.addToPage(firstPage, {
    x: 370,
    y: 223.48, // Coordinate (282, 234)
    size: 12,
    font,
    width: 128,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field18 = form.createTextField("field18");
  field18.setText("14");
  field18.addToPage(secondPage, {
    x: 255,
    y: 723, // Coordinate (282, 234)
    size: 14,
    font,
    width: 70,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field19 = form.createTextField("field19");
  field19.setText("December 2024");
  field19.addToPage(secondPage, {
    x: 373,
    y: 723, // Coordinate (282, 234)
    size: 14,
    font,
    width: 140,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field20 = form.createTextField("field20");
  field20.setText("Witness 1");
  field20.addToPage(secondPage, {
    x: 90,
    y: 665, // Coordinate (282, 234)
    font,
    width: 140,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field21 = form.createTextField("field21");
  field21.setText("Witness 2");
  field21.addToPage(secondPage, {
    x: 90,
    y: 630, // Coordinate (282, 234)
    size: 14,
    font,
    width: 140,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field22 = form.createTextField("field22");
  field22.setText("signature?");
  field22.addToPage(secondPage, {
    x: 380,
    y: 641, // Coordinate (282, 234)
    size: 14,
    font,
    width: 140,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  // PAGE 3

  const undersignedseller = form.createTextField("undersignedseller");
  undersignedseller.setText("Name of undersigned");
  undersignedseller.addToPage(thirdPage, {
    x: 238,
    y: 725,
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const day__ = form.createTextField("day__");
  day__.setText("14");
  day__.addToPage(thirdPage, {
    x: 210,
    y: 709,
    height: 17,
    width: 33,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const month__ = form.createTextField("month__");
  month__.setText("Decemeber");
  month__.addToPage(thirdPage, {
    x: 269,
    y: 709,
    height: 17,
    width: 63,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const year__ = form.createTextField("year__");
  year__.setText("1994");
  year__.addToPage(thirdPage, {
    x: 335,
    y: 709,
    height: 17,
    width: 46,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field23 = form.createTextField("field23");
  field23.setText("Samuel Modise");
  field23.addToPage(thirdPage, {
    x: 200,
    y: 615, // Coordinate (282, 234)
    size: 12,
    font,
    color: rgb(0, 0, 0),
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field241 = "4";
  textWidth = font.widthOfTextAtSize(field241, 13);
  centerX = (width - textWidth) / 2 - 50;
  const field24 = form.createTextField("field24");
  field24.setText(field241);
  field24.addToPage(thirdPage, {
    x: 254,
    y: 601, // Coordinate (282, 234)
    size: 13,
    font,
    width: 14,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field25 = form.createTextField("field25");
  field25.setText("November 1985");
  field25.addToPage(thirdPage, {
    x: 305,
    y: 601, // Coordinate (282, 234)
    size: 12,
    font,
    width: 100,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field26 = form.createTextField("field26");
  field26.setText("Micheal Smith");
  field26.addToPage(thirdPage, {
    x: 205,
    y: 576, // Coordinate (282, 234)
    size: 12,
    font,

    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field27 = form.createTextField("field27");
  field27.setText("lot");
  field27.addToPage(thirdPage, {
    x: 347,
    y: 515.5, // Coordinate (282, 234)
    size: 12,
    font,
    width: 95,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field28 = form.createTextField("field28");
  field28.setText("247548 Gaborone");
  field28.addToPage(thirdPage, {
    x: 213,
    y: 500, // Coordinate (282, 234)
    size: 12,
    font,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field29 = form.createTextField("field29");
  field29.setText("measuring");
  field29.addToPage(thirdPage, {
    x: 213,
    y: 456, // Coordinate (282, 234)
    size: 12,
    font,
    width: 313,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field30 = form.createTextField("field30");
  field30.setText("transfer number");
  field30.addToPage(thirdPage, {
    x: 356,
    y: 430.5, // Coordinate (282, 234)
    size: 12,
    font,
    width: 80,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field31 = form.createTextField("field31");
  field31.setText("16");
  field31.addToPage(thirdPage, {
    x: 468,
    y: 430.5, // Coordinate (282, 234)
    size: 12,
    font,
    width: 35,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field32 = form.createTextField("field32");
  field32.setText("Decemeber 2024");
  field32.addToPage(thirdPage, {
    x: 214,
    y: 418.3, // Coordinate (282, 234)
    size: 12,
    font,
    width: 105,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field33 = form.createTextField("field33");
  field33.setText("Masego Marang Modise");
  field33.addToPage(thirdPage, {
    x: 411,
    y: 418.3, // Coordinate (282, 234)
    size: 12,
    font,
    width: 128,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field34 = form.createTextField("field34");
  field34.setText("13");
  field34.addToPage(thirdPage, {
    x: 396,
    y: 394, // Coordinate (282, 234)
    size: 12,
    font,
    width: 40,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field35 = form.createTextField("field35");
  field35.setText("13");
  field35.addToPage(thirdPage, {
    x: 472,
    y: 394, // Coordinate (282, 234)
    size: 12,
    font,
    width: 60,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field36 = form.createTextField("field36");
  field36.setText("13");
  field36.addToPage(thirdPage, {
    x: 68,
    y: 382, // Coordinate (282, 234)
    size: 12,
    font,
    width: 63,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field37 = form.createTextField("field37");
  field37.setText("14");
  field37.addToPage(thirdPage, {
    x: 84,
    y: 345.6, // Coordinate (282, 234)
    size: 18,
    font,
    width: 400,
    color: rgb(0, 0, 0),
    height: 14.5,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field38 = form.createTextField("field38");
  field38.setText("14");
  field38.addToPage(forthPage, {
    x: 390,
    y: 375, // Coordinate (282, 234)
    size: 18,
    font,
    width: 120,
    color: rgb(0, 0, 0),
    height: 14.5,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field39 = form.createTextField("field39");
  field39.setText("date");
  field39.addToPage(forthPage, {
    x: 338,
    y: 297, // Coordinate (282, 234)
    size: 18,
    font,
    width: 40,
    color: rgb(0, 0, 0),
    height: 14.5,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field40 = form.createTextField("field40");
  field40.setText("14");
  field40.addToPage(forthPage, {
    x: 425,
    y: 297, // Coordinate (282, 234)
    size: 18,
    font,
    width: 100,
    color: rgb(0, 0, 0),
    height: 14.5,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field42 = form.createTextField("field42");
  field42.setText("14");
  field42.addToPage(fifthPage, {
    x: 250,
    y: 600, // Coordinate (282, 234)
    size: 18,
    font,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field43 = form.createTextField("field43");
  field43.setText("14");
  field43.addToPage(fifthPage, {
    x: 200,
    y: 503.4, // Coordinate (282, 234)
    size: 18,
    font,
    width: 30,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field44 = form.createTextField("field44");
  field44.setText("14");
  field44.addToPage(fifthPage, {
    x: 265,
    y: 503.4, // Coordinate (282, 234)
    size: 18,
    font,
    width: 60,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field45 = form.createTextField("field45");
  field45.setText("Barchelor");
  field45.addToPage(fifthPage, {
    x: 142,
    y: 466.6, // Coordinate (282, 234)
    size: 18,
    font,
    width: 60,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field46 = form.createTextField("field46");
  field46.setText("10th");
  field46.addToPage(fifthPage, {
    x: 458,
    y: 321, // Coordinate (282, 234)
    size: 18,
    font,
    width: 40,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field47 = form.createTextField("field47");
  field47.setText("10th");
  field47.addToPage(fifthPage, {
    x: 88,
    y: 309, // Coordinate (282, 234)
    size: 18,
    font,
    width: 75,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field48 = form.createTextField("field48");
  field48.setText("Name of field48");
  field48.addToPage(sixPage, {
    x: 240,
    y: 659,
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field49 = form.createTextField("field49");
  field49.setText("14");
  field49.addToPage(sixPage, {
    x: 255,
    y: 647,
    height: 14,
    width: 33,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field50 = form.createTextField("field50");
  field50.setText("Decemeber");
  field50.addToPage(sixPage, {
    x: 320,
    y: 647,
    height: 15,
    width: 60,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field51 = form.createTextField("field51");
  field51.setText("Decemeber");
  field51.addToPage(sixPage, {
    x: 270,
    y: 622,
    height: 15,
    width: 100,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field52 = form.createTextField("field52");
  field52.setText("12th");
  field52.addToPage(sixPage, {
    x: 420,
    y: 622,
    height: 15,
    width: 35,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field53 = form.createTextField("field53");
  field53.setText("12th");
  field53.addToPage(sixPage, {
    x: 493,
    y: 622,
    height: 15,
    width: 35,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field54 = form.createTextField("field54");
  field54.setText("12th");
  field54.addToPage(sixPage, {
    x: 70,
    y: 611,
    height: 15,
    width: 35,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field55 = form.createTextField("field55 ");
  field55.setText("12th");
  field55.addToPage(sixPage, {
    x: 70,
    y: 611,
    height: 15,
    width: 35,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field56 = form.createTextField("field56 ");
  field56.setText("Thabang Peabo");
  field56.addToPage(sixPage, {
    x: 310,
    y: 610.3,
    height: 15,
    width: 153,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field57 = form.createTextField("field57");
  field57.setText("Thabang Peabo");
  field57.addToPage(sixPage, {
    x: 145,
    y:598,
    height: 14,
    width: 82,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field58 = form.createTextField("field58");
  field58.setText("14th");
  field58.addToPage(sixPage, {
    x: 285,
    y:598,
    height: 15,
    width:40,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field59 = form.createTextField("field59");
  field59.setText("14th");
  field59.addToPage(sixPage, {
    x: 370,
    y:598,
    height: 15,
    width:134,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field60 = form.createTextField("field60");
  field60.setText("14th");
  field60.addToPage(sixPage, {
    x: 345,
    y:549,
    height: 15,
    width:100,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field61 = form.createTextField("field61");
  field61.setText("14th");
  field61.addToPage(sixPage, {
    x: 211,
    y:536,
    height: 15,
    width:115,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field62 = form.createTextField("field62");
  field62.setText("14th");
  field62.addToPage(sixPage, {
    x: 211,
    y:489,
    height: 15,
    width:300,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field63 = form.createTextField("field63");
  field63.setText("transfer number");
  field63.addToPage(sixPage, {
    x: 356,
    y: 465, // Coordinate (282, 234)
    size: 12,
    font,
    width:70,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field64 = form.createTextField("field64");
  field64.setText("16");
  field64.addToPage(sixPage, {
    x: 460,
    y: 464.5, // Coordinate (282, 234)
    size: 12,
    font,
    width: 30,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field65 = form.createTextField("field65");
  field65.setText("Decemeber 2024");
  field65.addToPage(sixPage, {
    x: 214,
    y: 452,  // Coordinate (282, 234)
    size: 12,
    font,
    width: 100,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field66 = form.createTextField("field66");
  field66.setText("Name");
  field66.addToPage(sixPage, {
    x: 378,
    y: 452,  // Coordinate (282, 234)
    size: 12,
    font,
    width: 105,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });
  }
  */

  // const field20 = form.createTextField("field20");
  // field20.setText("Witness 1");
  // field20.addToPage(secondPage, {
  //   x: 90,
  //   y: 665, // Coordinate (282, 234)
  //   font,
  //   width: 140,
  //   color: rgb(0, 0, 0),
  //   height: 15,
  //   borderColor: rgb(1, 1, 1),
  //   backgroundColor: rgb(1, 1, 1),
  // });

  // const field21 = form.createTextField("field21");
  // field21.setText("Witness 2");
  // field21.addToPage(secondPage, {
  //   x: 90,
  //   y: 630, // Coordinate (282, 234)
  //   size: 14,
  //   font,
  //   width: 140,
  //   color: rgb(0, 0, 0),
  //   height: 14,
  //   borderColor: rgb(1, 1, 1),
  //   backgroundColor: rgb(1, 1, 1),
  // });

  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default TransferEstate;
