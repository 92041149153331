import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/Company-IndividualTransfer.pdf";

const TransferCompanyIndividual = async (caseDetails) => {
  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();
  let details = await caseDetails?.transactionData?.formSchema.map(
    ({ name, value }) => {
      return { name, value };
    }
  );

  const flattenedObject = details?.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, {});

  details = {
    ...flattenedObject,
  };

  const pdfDoc = await PDFDocument.load(pdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const form = pdfDoc.getForm();

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const fourthPage = pdfDoc.getPage(3);
  const fifthPage = pdfDoc.getPage(4);
  const sixthPage = pdfDoc.getPage(5);
  const eighthPage = pdfDoc.getPage(7);
  const ninthPage = pdfDoc.getPage(8);
  const tenthPage = pdfDoc.getPage(9);
  const eleventhPage = pdfDoc.getPage(10);

  const { width, height } = firstPage.getSize();
  let textWidth = "";
  let centerX = "";

  //page 1
  if (1 == 1) {
    const field1 = form.createTextField("field1");
    field1.setText("Text 1");
    field1.addToPage(firstPage, {
      x: 259.78,
      y: 842 - 178.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field2 = form.createTextField("field2");
    field2.setText("Text 2");
    field2.addToPage(firstPage, {
      x: 188.95,
      y: 842 - 215.01656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field3 = form.createTextField("field3");
    field3.setText("Text 3");
    field3.addToPage(firstPage, {
      x: 298.44,
      y: 842 - 227.03656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field4 = form.createTextField("field4");
    field4.setText("Text 4");
    field4.addToPage(firstPage, {
      x: 380.78,
      y: 842 - 227.03656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field5 = form.createTextField("field5");
    field5.setText("Text 5");
    field5.addToPage(firstPage, {
      x: 70.824,
      y: 842 - 336.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field6 = form.createTextField("field6");
    field6.setText("Text 6");
    field6.addToPage(firstPage, {
      x: 153.41,
      y: 842 - 336.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field7 = form.createTextField("field7");
    field7.setText("Text 7");
    field7.addToPage(firstPage, {
      x: 253.3,
      y: 842 - 360.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field8 = form.createTextField("field8");
    field8.setText("Text 8");
    field8.addToPage(firstPage, {
      x: 302.76,
      y: 842 - 360.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field9 = form.createTextField("field9");
    field9.setText("Text 9");
    field9.addToPage(firstPage, {
      x: 264.1,
      y: 842 - 372.98656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field10 = form.createTextField("field10");
    field10.setText("Text 10");
    field10.addToPage(firstPage, {
      x: 336.6,
      y: 842 - 372.98656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field11 = form.createTextField("field11");
    field11.setText("Text 11");
    field11.addToPage(firstPage, {
      x: 257.14,
      y: 842 - 397.22656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field12 = form.createTextField("field12");
    field12.setText("Text 12");
    field12.addToPage(firstPage, {
      x: 306.6,
      y: 842 - 397.22656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field13 = form.createTextField("field13");
    field13.setText("Text 13");
    field13.addToPage(firstPage, {
      x: 267.46,
      y: 842 - 409.48656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field14 = form.createTextField("field14");
    field14.setText("Text 14");
    field14.addToPage(firstPage, {
      x: 333.0,
      y: 842 - 409.48656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field15 = form.createTextField("field15");
    field15.setText("Text 15");
    field15.addToPage(firstPage, {
      x: 363.98,
      y: 842 - 409.48656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field16 = form.createTextField("field16");
    field16.setText("Text 16");
    field16.addToPage(firstPage, {
      x: 332.76,
      y: 842 - 470.20656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field17 = form.createTextField("field17");
    field17.setText("Text 17");
    field17.addToPage(firstPage, {
      x: 335.64,
      y: 842 - 543.18656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field18 = form.createTextField("field18");
    field18.setText("Text 18");
    field18.addToPage(firstPage, {
      x: 436.73,
      y: 842 - 543.18656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field19 = form.createTextField("field19");
    field19.setText("Text 19");
    field19.addToPage(firstPage, {
      x: 362.3,
      y: 842 - 555.18656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 2
  if (1 == 1) {
    const field21 = form.createTextField("field21");
    field21.setText("Text 21");
    field21.addToPage(secondPage, {
      x: 255.22,
      y: 842 - 105.54656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field22 = form.createTextField("field22");
    field22.setText("Text 22");
    field22.addToPage(secondPage, {
      x: 347.18,
      y: 842 - 105.54656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field23 = form.createTextField("field23");
    field23.setText("Text 23");
    field23.addToPage(secondPage, {
      x: 415.61,
      y: 842 - 190.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field24 = form.createTextField("field24");
    field24.setText("Text 24");
    field24.addToPage(secondPage, {
      x: 471.07,
      y: 842 - 190.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field25 = form.createTextField("field25");
    field25.setText("Text 25");
    field25.addToPage(secondPage, {
      x: 255.22,
      y: 842 - 105.54656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field26 = form.createTextField("field26");
    field26.setText("Text 26");
    field26.addToPage(secondPage, {
      x: 347.18,
      y: 842 - 105.54656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field27 = form.createTextField("field27");
    field27.setText("Text 27");
    field27.addToPage(secondPage, {
      x: 415.61,
      y: 842 - 190.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field28 = form.createTextField("field28");
    field28.setText("Text 28");
    field28.addToPage(secondPage, {
      x: 471.07,
      y: 842 - 190.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field29 = form.createTextField("field29");
    field29.setText("Text 29");
    field29.addToPage(secondPage, {
      x: 321.98,
      y: 842 - 275.75656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field30 = form.createTextField("field30");
    field30.setText("Text 30");
    field30.addToPage(secondPage, {
      x: 296.12,
      y: 842 - 306.26656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 3
  if (1 == 1) {
    const field31 = form.createTextField("field31");
    field31.setText("Text 31");
    field31.addToPage(thirdPage, {
      x: 246.82,
      y: 842 - 129.78656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field32 = form.createTextField("field32");
    field32.setText("Text 32");
    field32.addToPage(thirdPage, {
      x: 302.25,
      y: 842 - 129.78656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field33 = form.createTextField("field33");
    field33.setText("Text 33");
    field33.addToPage(thirdPage, {
      x: 171.91,
      y: 842 - 166.29656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field34 = form.createTextField("field34");
    field34.setText("Text 34");
    field34.addToPage(thirdPage, {
      x: 229.06,
      y: 842 - 166.29656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field35 = form.createTextField("field35");
    field35.setText("Text 35");
    field35.addToPage(thirdPage, {
      x: 299.16,
      y: 842 - 178.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field36 = form.createTextField("field36");
    field36.setText("Text 36");
    field36.addToPage(thirdPage, {
      x: 373.82,
      y: 842 - 178.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field37 = form.createTextField("field37");
    field37.setText("Text 37");
    field37.addToPage(thirdPage, {
      x: 245.62,
      y: 842 - 263.51656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field38 = form.createTextField("field38");
    field38.setText("Text 38");
    field38.addToPage(thirdPage, {
      x: 302.76,
      y: 842 - 263.51656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field39 = form.createTextField("field39");
    field39.setText("Text 39");
    field39.addToPage(thirdPage, {
      x: 260.74,
      y: 842 - 275.75656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field40 = form.createTextField("field40");
    field40.setText("Text 40");
    field40.addToPage(thirdPage, {
      x: 333.24,
      y: 842 - 275.75656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field41 = form.createTextField("field41");
    field41.setText("Text 41");
    field41.addToPage(thirdPage, {
      x: 364.17,
      y: 842 - 275.75656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field42 = form.createTextField("field42");
    field42.setText("Text 42");
    field42.addToPage(thirdPage, {
      x: 249.46,
      y: 842 - 299.99656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field43 = form.createTextField("field43");
    field43.setText("Text 43");
    field43.addToPage(thirdPage, {
      x: 306.6,
      y: 842 - 299.99656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field44 = form.createTextField("field44");
    field44.setText("Text 44");
    field44.addToPage(thirdPage, {
      x: 260.74,
      y: 842 - 312.26656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field45 = form.createTextField("field45");
    field45.setText("Text 45");
    field45.addToPage(thirdPage, {
      x: 333.24,
      y: 842 - 312.26656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field46 = form.createTextField("field46");
    field46.setText("Text 46");
    field46.addToPage(thirdPage, {
      x: 370.94,
      y: 842 - 312.26656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field47 = form.createTextField("field47");
    field47.setText("Text 47");
    field47.addToPage(thirdPage, {
      x: 332.76,
      y: 842 - 372.98656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field48 = form.createTextField("field48");
    field48.setText("Text 48");
    field48.addToPage(thirdPage, {
      x: 333.24,
      y: 842 - 445.96656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field49 = form.createTextField("field49");
    field49.setText("Text 49");
    field49.addToPage(thirdPage, {
      x: 438.65,
      y: 842 - 445.96656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field50 = form.createTextField("field50");
    field50.setText("Text 50");
    field50.addToPage(thirdPage, {
      x: 214.87,
      y: 842 - 457.96656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field51 = form.createTextField("field51");
    field51.setText("Text 51");
    field51.addToPage(thirdPage, {
      x: 347.42,
      y: 842 - 457.96656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field52 = form.createTextField("field52");
    field52.setText("Text 52");
    field52.addToPage(thirdPage, {
      x: 405.02,
      y: 842 - 457.96656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field53 = form.createTextField("field53");
    field53.setText("Text 53");
    field53.addToPage(thirdPage, {
      x: 457.61,
      y: 842 - 494.46656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field54 = form.createTextField("field54");
    field54.setText("Text 54");
    field54.addToPage(thirdPage, {
      x: 70.824,
      y: 842 - 506.70656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field55 = form.createTextField("field55");
    field55.setText("Text 55");
    field55.addToPage(thirdPage, {
      x: 114.01,
      y: 842 - 506.70656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 4
  if (1 == 1) {
    const field56 = form.createTextField("field56");
    field56.setText("Text 56");
    field56.addToPage(fourthPage, {
      x: 337.8,
      y: 842 - 603.93656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field57 = form.createTextField("field57");
    field57.setText("Text 57");
    field57.addToPage(fourthPage, {
      x: 422.33,
      y: 842 - 603.93656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field58 = form.createTextField("field58");
    field58.setText("Text 58");
    field58.addToPage(fourthPage, {
      x: 463.13,
      y: 842 - 603.93656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 5
  if (1 == 1) {
    const field59 = form.createTextField("field59");
    field59.setText("Text 59");
    field59.addToPage(fifthPage, {
      x: 185.11,
      y: 842 - 93.30656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field60 = form.createTextField("field60");
    field60.setText("Text 60");
    field60.addToPage(fifthPage, {
      x: 226.87,
      y: 842 - 93.30656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field61 = form.createTextField("field61");
    field61.setText("Text 61");
    field61.addToPage(fifthPage, {
      x: 305.16,
      y: 842 - 105.54656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field62 = form.createTextField("field62");
    field62.setText("Text 62");
    field62.addToPage(fifthPage, {
      x: 398.3,
      y: 842 - 105.54656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field63 = form.createTextField("field63");
    field63.setText("Text 63");
    field63.addToPage(fifthPage, {
      x: 438.89,
      y: 842 - 105.54656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field64 = form.createTextField("field64");
    field64.setText("Text 64");
    field64.addToPage(fifthPage, {
      x: 253.3,
      y: 842 - 190.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field65 = form.createTextField("field65");
    field65.setText("Text 65");
    field65.addToPage(fifthPage, {
      x: 310.44,
      y: 842 - 190.53656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field66 = form.createTextField("field66");
    field66.setText("Text 66");
    field66.addToPage(fifthPage, {
      x: 264.1,
      y: 842 - 202.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field67 = form.createTextField("field67");
    field67.setText("Text 67");
    field67.addToPage(fifthPage, {
      x: 336.6,
      y: 842 - 202.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field68 = form.createTextField("field68");
    field68.setText("Text 68");
    field68.addToPage(fifthPage, {
      x: 367.53,
      y: 842 - 202.77656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field69 = form.createTextField("field69");
    field69.setText("Text 69");
    field69.addToPage(fifthPage, {
      x: 257.14,
      y: 842 - 227.03656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field70 = form.createTextField("field70");
    field70.setText("Text 70");
    field70.addToPage(fifthPage, {
      x: 306.6,
      y: 842 - 227.03656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field71 = form.createTextField("field71");
    field71.setText("Text 71");
    field71.addToPage(fifthPage, {
      x: 264.1,
      y: 842 - 239.27656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field72 = form.createTextField("field72");
    field72.setText("Text 72");
    field72.addToPage(fifthPage, {
      x: 329.64,
      y: 842 - 239.27656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field73 = form.createTextField("field73");
    field73.setText("Text 73");
    field73.addToPage(fifthPage, {
      x: 367.58,
      y: 842 - 239.27656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field74 = form.createTextField("field74");
    field74.setText("Text 74");
    field74.addToPage(fifthPage, {
      x: 332.76,
      y: 842 - 348.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field75 = form.createTextField("field75");
    field75.setText("Text 75");
    field75.addToPage(fifthPage, {
      x: 330.12,
      y: 842 - 421.48656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field76 = form.createTextField("field76");
    field76.setText("Text 76");
    field76.addToPage(fifthPage, {
      x: 441.05,
      y: 842 - 421.48656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field77 = form.createTextField("field77");
    field77.setText("Text 77");
    field77.addToPage(fifthPage, {
      x: 345.02,
      y: 842 - 433.72656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field78 = form.createTextField("field78");
    field78.setText("Text 78");
    field78.addToPage(fifthPage, {
      x: 396.14,
      y: 842 - 433.72656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field79 = form.createTextField("field79");
    field79.setText("Text 79");
    field79.addToPage(fifthPage, {
      x: 97.248,
      y: 842 - 494.46656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field80 = form.createTextField("field80");
    field80.setText("Text 80");
    field80.addToPage(fifthPage, {
      x: 175.27,
      y: 842 - 494.46656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 6
  if (1 == 1) {
    const field81 = form.createTextField("field81");
    field81.setText("Text 81");
    field81.addToPage(sixthPage, {
      x: 242.02,
      y: 842 - 127.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field82 = form.createTextField("field82");
    field82.setText("Text 82");
    field82.addToPage(sixthPage, {
      x: 306.84,
      y: 842 - 127.38656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field83 = form.createTextField("field83");
    field83.setText("Text 83");
    field83.addToPage(sixthPage, {
      x: 260.74,
      y: 842 - 139.65656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field84 = form.createTextField("field84");
    field84.setText("Text 84");
    field84.addToPage(sixthPage, {
      x: 333.24,
      y: 842 - 139.65656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field85 = form.createTextField("field85");
    field85.setText("Text 85");
    field85.addToPage(sixthPage, {
      x: 249.46,
      y: 842 - 163.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field86 = form.createTextField("field86");
    field86.setText("Text 86");
    field86.addToPage(sixthPage, {
      x: 306.6,
      y: 842 - 163.89656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field87 = form.createTextField("field87");
    field87.setText("Text 87");
    field87.addToPage(sixthPage, {
      x: 257.14,
      y: 842 - 176.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field88 = form.createTextField("field88");
    field88.setText("Text 88");
    field88.addToPage(sixthPage, {
      x: 329.64,
      y: 842 - 176.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field89 = form.createTextField("field89");
    field89.setText("Text 89");
    field89.addToPage(sixthPage, {
      x: 374.3,
      y: 842 - 176.13656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field90 = form.createTextField("field90");
    field90.setText("Text 90");
    field90.addToPage(sixthPage, {
      x: 172.15,
      y: 842 - 261.11656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field91 = form.createTextField("field91");
    field91.setText("Text 91");
    field91.addToPage(sixthPage, {
      x: 229.3,
      y: 842 - 261.11656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field92 = form.createTextField("field92");
    field92.setText("Text 92");
    field92.addToPage(sixthPage, {
      x: 332.76,
      y: 842 - 321.86656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field93 = form.createTextField("field93");
    field93.setText("Text 93");
    field93.addToPage(sixthPage, {
      x: 323.64,
      y: 842 - 394.82656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field94 = form.createTextField("field94");
    field94.setText("Text 94");
    field94.addToPage(sixthPage, {
      x: 437.93,
      y: 842 - 394.82656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field95 = form.createTextField("field95");
    field95.setText("Text 95");
    field95.addToPage(sixthPage, {
      x: 503.71,
      y: 842 - 394.82656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field96 = form.createTextField("field96");
    field96.setText("Text 96");
    field96.addToPage(sixthPage, {
      x: 413.21,
      y: 842 - 407.08656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
    const field97 = form.createTextField("field97");
    field97.setText("Text 97");
    field97.addToPage(sixthPage, {
      x: 477.55,
      y: 842 - 407.08656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field98 = form.createTextField("field98");
    field98.setText("Text 98");
    field98.addToPage(sixthPage, {
      x: 414.89,
      y: 842 - 443.56656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field99 = form.createTextField("field99");
    field99.setText("Text 99");
    field99.addToPage(sixthPage, {
      x: 485.95,
      y: 842 - 443.56656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 8
  if (1 == 1) {
    const field100 = form.createTextField("field100");
    field100.setText("Text 100");
    field100.addToPage(eighthPage, {
      x: 249.46,
      y: 842 - 263.51656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field101 = form.createTextField("field101");
    field101.setText("Text 101");
    field101.addToPage(eighthPage, {
      x: 306.6,
      y: 842 - 263.51656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field102 = form.createTextField("field102");
    field102.setText("Text 102");
    field102.addToPage(eighthPage, {
      x: 213.91,
      y: 842 - 360.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field103 = form.createTextField("field103");
    field103.setText("Text 103");
    field103.addToPage(eighthPage, {
      x: 294.84,
      y: 842 - 360.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field104 = form.createTextField("field104");
    field104.setText("Text 104");
    field104.addToPage(eighthPage, {
      x: 349.02,
      y: 842 - 360.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field105 = form.createTextField("field105");
    field105.setText("Text 105");
    field105.addToPage(eighthPage, {
      x: 321.96,
      y: 842 - 397.22656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field106 = form.createTextField("field106");
    field106.setText("Text 106");
    field106.addToPage(eighthPage, {
      x: 370.94,
      y: 842 - 397.22656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field107 = form.createTextField("field107");
    field107.setText("Text 107");
    field107.addToPage(eighthPage, {
      x: 459.29,
      y: 842 - 567.42656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field108 = form.createTextField("field108");
    field108.setText("Text 108");
    field108.addToPage(eighthPage, {
      x: 91.224,
      y: 842 - 579.69656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field109 = form.createTextField("field109");
    field109.setText("Text 109");
    field109.addToPage(eighthPage, {
      x: 134.69,
      y: 842 - 579.69656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 9
  if (1 == 1) {
    const field110 = form.createTextField("field110");
    field110.setText("Text 110");
    field110.addToPage(ninthPage, {
      x: 240.1,
      y: 842 - 567.42656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field111 = form.createTextField("field111");
    field111.setText("Text 111");
    field111.addToPage(ninthPage, {
      x: 281.88,
      y: 842 - 567.42656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field112 = form.createTextField("field112");
    field112.setText("Text 112");
    field112.addToPage(ninthPage, {
      x: 323.4,
      y: 842 - 567.42656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field113 = form.createTextField("field113");
    field113.setText("Text 113");
    field113.addToPage(ninthPage, {
      x: 161.11,
      y: 842 - 616.1765599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field114 = form.createTextField("field114");
    field114.setText("Text 114");
    field114.addToPage(ninthPage, {
      x: 242.5,
      y: 842 - 616.1765599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field115 = form.createTextField("field115");
    field115.setText("Text 115");
    field115.addToPage(ninthPage, {
      x: 246.82,
      y: 842 - 640.4165599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field116 = form.createTextField("field116");
    field116.setText("Text 116");
    field116.addToPage(ninthPage, {
      x: 302.28,
      y: 842 - 640.4165599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field117 = form.createTextField("field117");
    field117.setText("Text 117");
    field117.addToPage(ninthPage, {
      x: 171.91,
      y: 842 - 676.9165599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field118 = form.createTextField("field118");
    field118.setText("Text 118");
    field118.addToPage(ninthPage, {
      x: 221.38,
      y: 842 - 676.9165599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field119 = form.createTextField("field119");
    field119.setText("Text 119");
    field119.addToPage(ninthPage, {
      x: 279.94,
      y: 842 - 688.9165599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field120 = form.createTextField("field120");
    field120.setText("Text 120");
    field120.addToPage(ninthPage, {
      x: 354.62,
      y: 842 - 688.9165599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field121 = form.createTextField("field121");
    field121.setText("Text 121");
    field121.addToPage(ninthPage, {
      x: 425.41184,
      y: 842 - 688.9165599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field122 = form.createTextField("field122");
    field122.setText("Text 122");
    field122.addToPage(ninthPage, {
      x: 351.98,
      y: 842 - 713.3965599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field123 = form.createTextField("field123");
    field123.setText("Text 123");
    field123.addToPage(ninthPage, {
      x: 427.13,
      y: 842 - 713.3965599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field124 = form.createTextField("field124");
    field124.setText("Text 124");
    field124.addToPage(ninthPage, {
      x: 467.92568,
      y: 842 - 713.3965599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field125 = form.createTextField("field125");
    field125.setText("Text 125");
    field125.addToPage(ninthPage, {
      x: 475.63,
      y: 842 - 786.3825599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 10
  if (1 == 1) {
    const field126 = form.createTextField("field126");
    field126.setText("Text 126");
    field126.addToPage(tenthPage, {
      x: 253.3,
      y: 842 - 93.30655999999988,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field127 = form.createTextField("field127");
    field127.setText("Text 127");
    field127.addToPage(tenthPage, {
      x: 310.41584,
      y: 842 - 93.30655999999988,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field128 = form.createTextField("field128");
    field128.setText("Text 128");
    field128.addToPage(tenthPage, {
      x: 264.1,
      y: 842 - 105.54655999999989,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field129 = form.createTextField("field129");
    field129.setText("Text 129");
    field129.addToPage(tenthPage, {
      x: 336.6,
      y: 842 - 105.54655999999989,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field130 = form.createTextField("field130");
    field130.setText("Text 130");
    field130.addToPage(tenthPage, {
      x: 367.52544,
      y: 842 - 105.54655999999989,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field131 = form.createTextField("field131");
    field131.setText("Text 131");
    field131.addToPage(tenthPage, {
      x: 257.14,
      y: 842 - 129.7865599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field132 = form.createTextField("field132");
    field132.setText("Text 132");
    field132.addToPage(tenthPage, {
      x: 306.5824,
      y: 842 - 129.7865599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field133 = form.createTextField("field133");
    field133.setText("Text 133");
    field133.addToPage(tenthPage, {
      x: 264.1,
      y: 842 - 142.05655999999988,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field134 = form.createTextField("field134");
    field134.setText("Text 134");
    field134.addToPage(tenthPage, {
      x: 329.64,
      y: 842 - 142.05655999999988,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field135 = form.createTextField("field135");
    field135.setText("Text 135");
    field135.addToPage(tenthPage, {
      x: 367.51056,
      y: 842 - 142.05655999999988,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field136 = form.createTextField("field136");
    field136.setText("Text 136");
    field136.addToPage(tenthPage, {
      x: 332.76,
      y: 842 - 202.7765599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field137 = form.createTextField("field137");
    field137.setText("Text 137");
    field137.addToPage(tenthPage, {
      x: 432.41,
      y: 842 - 372.98656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field138 = form.createTextField("field138");
    field138.setText("Text 138");
    field138.addToPage(tenthPage, {
      x: 212.71,
      y: 842 - 384.98656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field139 = form.createTextField("field139");
    field139.setText("Text 139");
    field139.addToPage(tenthPage, {
      x: 296.28,
      y: 842 - 384.98656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field140 = form.createTextField("field140");
    field140.setText("Text 140");
    field140.addToPage(tenthPage, {
      x: 337.8,
      y: 842 - 384.98656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field141 = form.createTextField("field141");
    field141.setText("Text 141");
    field141.addToPage(tenthPage, {
      x: 485.23,
      y: 842 - 384.98656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field142 = form.createTextField("field142");
    field142.setText("Text 142");
    field142.addToPage(tenthPage, {
      x: 212.71,
      y: 842 - 397.22655999999995,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field143 = form.createTextField("field143");
    field143.setText("Text 143");
    field143.addToPage(tenthPage, {
      x: 226.66,
      y: 842 - 494.46655999999996,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }
  //page 11
  if (1 == 1) {
    const field144 = form.createTextField("field144");
    field144.setText("Text 144");
    field144.addToPage(eleventhPage, {
      x: 253.3,
      y: 842 - 299.99655999999993,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field145 = form.createTextField("field145");
    field145.setText("Text 145");
    field145.addToPage(eleventhPage, {
      x: 310.41584,
      y: 842 - 299.99655999999993,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field146 = form.createTextField("field146");
    field146.setText("Text 146");
    field146.addToPage(eleventhPage, {
      x: 264.0616,
      y: 842 - 312.2665599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field147 = form.createTextField("field147");
    field147.setText("Text 147");
    field147.addToPage(eleventhPage, {
      x: 336.6,
      y: 842 - 312.2665599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field148 = form.createTextField("field148");
    field148.setText("Text 148");
    field148.addToPage(eleventhPage, {
      x: 367.52544,
      y: 842 - 312.2665599999999,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field149 = form.createTextField("field149");
    field149.setText("Text 149");
    field149.addToPage(eleventhPage, {
      x: 257.14,
      y: 842 - 336.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field150 = form.createTextField("field150");
    field150.setText("Text 150");
    field150.addToPage(eleventhPage, {
      x: 306.5824,
      y: 842 - 336.50656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field151 = form.createTextField("field151");
    field151.setText("Text 151");
    field151.addToPage(eleventhPage, {
      x: 264.1,
      y: 842 - 348.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field152 = form.createTextField("field152");
    field152.setText("Text 152");
    field152.addToPage(eleventhPage, {
      x: 329.64,
      y: 842 - 348.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });

    const field153 = form.createTextField("field153");
    field153.setText("Text 153");
    field153.addToPage(eleventhPage, {
      x: 367.51056,
      y: 842 - 348.74656,
      size: 12,
      font,
      width: 40,
      height: 14.5,
      color: rgb(0, 0, 0),
      borderColor: rgb(1, 1, 1),
      backgroundColor: rgb(1, 1, 1),
    });
  }

  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default TransferCompanyIndividual;
