import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { IoMdArrowBack } from "react-icons/io";
import { RxDragHandleDots2 } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";
import { IoAdd } from "react-icons/io5";
import {setReport} from '../../store/reportSlice'
import { useDispatch } from "react-redux";
import Pagination from "../../components/Pagination";
import { clearTransaction, setEditId,setIsOverride } from "../../store/transactionSlice";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
} from "@material-tailwind/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDialog, useModal } from "../../hooks/useModal";
import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";

const navigation = [];
const api = process.env.REACT_APP_BACKEND_API_URL;

const TABLE_HEAD = [
  "TEMPLATE NO#",
  "TEMPLATE NAME",
  "TEMPLATE DESCRIPTION",
  "TEMPLATE TYPE",
  "CREATED BY",
  "ACTIONS",
];

export default function SavedTemplates() {


  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [query, setQuery] = useState("");
  const [TABLE_ROWS, setDataTable] = useState([]);
  const [templateType,setTemplateType]= useState("transactions")
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  
  const fetch = () => {
    axios.get(`${api}/api/v1/forms`).then((res) => {
      const filteredData = res.data.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      console.log({ filteredData });

      if (query === "") {
        setDataTable(filteredData);
      } else {
        const filteredSearchData = filteredData.filter((form) => {
          return (
            form.formNumber.toString().toLowerCase() ===
              query.toString().toLowerCase() ||
            form.formNumber
              ?.toString()
              ?.toLowerCase()
              ?.includes(query.toString().toLowerCase()) ||
            form?.formName
              ?.toString()
              ?.toLowerCase()
              ?.includes(query.toString().toLowerCase())
          );
        });
        setDataTable(filteredSearchData);
      }
    });
  };


  const fetchReports = () => {
    setCurrentPage(1);
    axios.get(`${api}/api/v1/report-templates`).then((res) => {
      const filteredData = res.data.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      if (query === "") {
        setDataTable(filteredData);
      } else {
        const filteredSearchData = filteredData.filter((form) => {
          return (
            form?.reportType.toString().toLowerCase() ===
              query.toString().toLowerCase() ||
            form?.reportType
              .toString()
              .toLowerCase()
              .includes(query.toString().toLowerCase())
          );
        });
        setDataTable(filteredSearchData);
      }
    });
  };



  useEffect(() => {
    if(templateType === "transactions"){
      fetch();
    }else{
      fetchReports();
    }
   
  }, [query,templateType]);

  const modal = useModal();
  const dialog = useDialog();

  const DeleteTemplate = ({_id}) => {
    try {
      // modal.showModal("Deleting form...", "Please wait");
      if(templateType === "transactions"){
      axios.delete(`${api}/api/v1/forms/${_id}`).then((res) => {
        fetch();
        modal.closeModal();
        dialog.showModal("Success", "Form deleted successfully");
      });}else{
        axios.delete(`${api}/api/v1/report-templates/${_id}`).then((res) => {
          fetchReports();
          modal.closeModal();
          dialog.showModal("Success", "Form deleted successfully");
        });
      }
    } catch (error) {
      modal.closeModal();
      console.log(error);
    }
  };

  const ViewTemplate = ({_id}) => {
    try {
      if(templateType === "transactions")
     { axios
        .get(`${api}/api/v1/forms/${_id}`)
        .then((res) => {
          const data = res.data.data;
          localStorage.setItem("updateTemp", "update");
          dispatch(clearTransaction());
          dispatch(setEditId(_id));   
          dispatch(setIsOverride(true))
          history.push({
            pathname: "/templates/new-templates",
            data: { data, showFormDialog: false },
          });
        });}
        else{
          axios
        .get(`${api}/api/v1/report-templates/${_id}`)
        .then((res) => {
          const data = res.data.data;
          dispatch(setReport(data));
          localStorage.setItem("updateTemp", "update");
          history.push({
            pathname: "/templates/new-templates",
            data: { data, showFormDialog: false },
          });
        });
        }
    } catch (error) {
      console.log(error);
    }
  };

 // Get current posts
 const indexOfLastPost = currentPage * postsPerPage;
 const indexOfFirstPost = indexOfLastPost - postsPerPage;
 const currentPosts = TABLE_ROWS.slice(indexOfFirstPost, indexOfLastPost);

 // Change page
 const paginate = pageNumber => {console.log("Mike" + pageNumber);

                                 setCurrentPage(pageNumber)
                                };



  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/app/home" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex  flex mb-8 items-center   text-xl items-center justify-between container mx-auto px-[20px] lg:px-[100px] ">
        <div>
          <span
            aria-hidden="true "
            className="cursor-pointer "
            onClick={() => {
               history.push('/');
            }}
          >
            {<IoMdArrowBack />}
          </span>
        </div>
        <div>
          <label className="text-xl ml-[70px] flex justify-center font-medium">
            <span className="font-thin text-[#d8d9da] mr-2"></span>Saved Templates
          </label>
        </div>
        <div>
          <button
            onClick={() => {
              history.push("/templates/new-templates");
            }}
            className="bg-[#2b2d77] gap-2 container mx-auto align-center flex justify-center text-sm items-center rounded-[9px] lg:px-[30px] text-white p-3 hover:scale-105 duration-300 h-[40px]"
          >
            <IoAdd color="#ffffff" size={20} /> <p> New</p>
          </button>
        </div>
      </div>

   <div className="container mx-auto px-[20px] lg:px-[200px] ">
        <Card className="bg-[#081c2f] shadow-[60%] py-1 rounded-xl border border-[#636c70]">
          <CardHeader
            floated={false}
            shadow={false}
            className="rounded-none bg-transparent my-1 text-[#e9e9e9]"
          >
            <div className="flex items-center  px-6 pt-8 gap-2">
              <div>
                <label className="p-1.5 rounded-[5px] input:bg-red-700 input-bordered bg-transparent border border-[#636c70] flex items-center gap-2">
                  <input
                    type="text"
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                    className="bg-transparent outline-none "
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="w-4 h-4 opacity-70"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </label>
              </div>
              <div>
                <div class="">
                  <select
                    value={templateType}
                    onChange={(e) => setTemplateType(e.target.value)}
                    className="p-[0.47rem]  bg-transparent border border-[#636c70] flex items-center gap-2 text-[#636c70] outline-none rounded-[5px]"
                  >
                    <option
                      className="bg-[#081c2f] h-[400px] rounded-none pt-[1px]"
                      value="reports"
                    >
                      Report Templates
                    </option>
                    <option className="h-[40px]" value="transactions">
                      Transactions Reports
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </CardHeader>

          <CardBody className="w-full relative flex flex-col text-white   max-h-[580px]">
            <div
              class="inline-block   py-2 [&::-webkit-scrollbar]:h-1.5
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700 mx-1
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500  overflow-x-auto  "
            >
              <table class="min-w-full  text-left text-sm  text-surface text-[#454545]">
                <thead class="font-thin">
                  <tr className="text-sm">
                    {TABLE_HEAD.map((head, index) => (
                      <th
                        key={head}
                        className="cursor-pointer px-6 text-xs font-medium"
                      >
                        <p className="text-[#d8d9da]">{head}</p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentPosts?.map((item) => (
                    <tr class="text-xs">
                      <td class="whitespace-nowrap px-6 py-4 font-medium ">
                        <div className="flex items-center gap-2">
                          <div className="w-fit flex items-center rounded">
                            <RxDragHandleDots2 size={20} color="#d8d9da" />
                          </div>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              className="font-normal text-[#d8d9da]"
                            >
                              {item?.formNumber || item?.reportNumber}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td class="whitespace-nowrap px-6 text-[#d8d9da]">
                        {item.formType !== "report"
                          ? `${item?.formName?.substring(0, 35)}...`
                          : `${item.reportName?.substring(0, 35)}...`}
                      </td>
                      <td className="whitespace-nowrap px-6 text-[#d8d9da]">
                        {item.formDescription?.length > 10 ||
                        item.reportDescription?.length > 40
                          ? `${item.formDescription?.substring(0, 30)}...` ||
                            `${item.reportDescription?.substring(0, 30)}...`
                          : `${item.reportDescription?.substring(0, 30)}...` ||
                            item?.reportDescription}
                      </td>
                      <td class="whitespace-nowrap px-6 text-[#d8d9da]">
                        {item?.formType || item?.reportType}
                      </td>
                      <td class="whitespace-nowrap px-6  text-[#d8d9da]">
                        {item.createdBy}
                      </td>
                      <td
                        class="whitespace-nowrap px-1

                            
                            text-[#d8d9da]"
                      >
                        <div className="flex">
                          <Button
                            key={item._id}
                            className="flex justify-start items-start"
                            onClick={() => {
                              ViewTemplate(item);
                            }}
                            variant="text"
                          >
                            <IoEyeOutline size={16} color="#929ba2" />
                          </Button>

                          <IconButton
                            className="flex justify-start items-center "
                            onClick={() => {
                              DeleteTemplate(item);
                            }}
                            variant="text"
                          >
                            <RiDeleteBin6Line size={16} color="#929ba2" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>

          <CardFooter className="flex py-1 px-3 sm:px-10 lg:px-12  mx-1 ">
            <Pagination
              currentPage={currentPage}
              postsPerPage={postsPerPage}
              totalPosts={TABLE_ROWS.length}
              paginate={paginate}
            />
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}
