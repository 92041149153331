import BondAbsa from "../../PDFComponents/BondAbsa";
import IndividualToIndividualTransfer from "../../PDFComponents/TransferIndividual";
import CompanyToIndividualTransfer from "../../PDFComponents/TransferCompany-Individual";
import EstateTransfer from "../../PDFComponents/TransferEstate";
import MortgageBondApplicationAccess from "../../PDFComponents/MortgageBondApplicationAccess";
import MortgageBondApplicationFnb from "../../PDFComponents/MortgageBondApplicationFnb";
import MortgageBondApplicationBbs from "../../PDFComponents/MortgageBondApplicationBbs";

import axios from "axios";

const api = "https://collins-chilisa.testlive.space/api/";

export const processTransaction = async (caseDetails) => {
  console.log({ caseDetails });
  console.log({ caseDetails });
  console.log({ caseDetails });

  const savefile = async (data) => {
    console.log({ data });
    try {
      // Convert Uint8Array to a Blob
      const uint8Array = new Uint8Array(data);
      const blob = new Blob([uint8Array], {
        type: "application/octet-stream", // Set the appropriate MIME type
      });
      const file = new File(
        [blob],
        `${caseDetails?.transactionData.formName?.toString()}_Case Document.pdf`
      ); // Set a filename for the upload

      const formData = new FormData();
      formData.append("file", file); // Use 'file' as the key based on your server-side setup

      // Post the file using Axios
      const response = await axios.post(`${api}v1/files`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Return the uploaded file URL
      return response.data.data.url;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error; // Optionally re-throw the error for further handling
    }
  };

  let transactionType =
    caseDetails?.transactionData.formName?.toString().trim() || "Unknown";

  let method;

  switch (transactionType.toLowerCase()) {
    case "cancellation of mortgage bond":
      method = await savefile(await BondAbsa(caseDetails));
      break;
    case "individual to individual transfer":
      method = await savefile(
        await IndividualToIndividualTransfer(caseDetails)
      );
      break;
    case "company to individual transfer":
      method = await savefile(await CompanyToIndividualTransfer(caseDetails));
      break;
    case "estate transfer":
      method = await savefile(await EstateTransfer(caseDetails));
      break;

    case "application of mortgage bond- fnb":
      method = await savefile(await MortgageBondApplicationFnb(caseDetails));
      break;

    case "application of mortgage bond- bbs":
      method = await savefile(await MortgageBondApplicationBbs(caseDetails));
      break;

    case "application of mortgage bond- access":
      method = await savefile(await MortgageBondApplicationAccess(caseDetails));
      break;
    default:
      method = "Invalid transaction type";
  }

  console.log(method);

  return method;
};

export const processPreviewTransaction = async (caseDetails) => {
  console.log({ caseDetails });
  console.log({ caseDetails });
  console.log({ caseDetails });

  let transactionType =
    caseDetails?.transactionData.formName?.toString().trim() || "Unknown";

  let method;

  switch (transactionType.toLowerCase()) {
    case "cancellation of mortgage bond":
      method = await BondAbsa(caseDetails);
      break;
    case "individual to individual transfer":
      method = await IndividualToIndividualTransfer(caseDetails);
      break;
    case "company to individual transfer":
      method = await CompanyToIndividualTransfer(caseDetails);
      break;
    case "estate transfer":
      method = await EstateTransfer(caseDetails);
      break;

    case "application of mortgage bond- fnb":
      method = await MortgageBondApplicationFnb(caseDetails);
      break;

    case "application of mortgage bond- bbs":
      method = await MortgageBondApplicationBbs(caseDetails);
      break;

    case "application of mortgage bond- access":
      method = await MortgageBondApplicationAccess(caseDetails);
      break;

    default:
      method = "Invalid transaction type";
  }

  console.log(method);

  return method;
};
