import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

const BondAbsa = async (pdfBytes, individualDetails) => {
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  // Modify each page with individual details

  ////////////////////////////BondAbsa BondAbsa BondAbsa//////////////////////////////////////////////
  //page 1
  if (individualDetails.firstPageDetails) {
    const firstPage = pdfDoc.getPage(0);
     
    
    const { width, height } = firstPage.getSize();
    firstPage.drawText(individualDetails.firstPageDetails, {
      x: 455,
      y: 285,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    const textWidth = font.widthOfTextAtSize(
      individualDetails.firstPageDetails,
      12
    );
    const centerX = (width - textWidth) / 2;
  
  
  
    firstPage.drawText("Testing1", {
      x: centerX,
      y: height / 2 - 197,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });


    firstPage.drawText("Testing2", {
      x: width - 140 ,
      y: height / 2 - 235,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText("Testing3", {
      x: 66 ,
      y: height / 2 - 245,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    firstPage.drawText("Testing4", {
      x: centerX ,
      y: height / 2 - 283,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

  }
  //page 2
  if (individualDetails.secondPageDetails) {
    const secondPage = pdfDoc.getPage(1);
    const textWidth = font.widthOfTextAtSize(
      individualDetails.secondPageDetails,
      12
    );
    const { width, height } = secondPage.getSize();
    const centerX = (width - textWidth) / 2;
    secondPage.drawText(individualDetails.secondPageDetails, {
      x: centerX,
      y: height-107,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    
  }

  //page 3
  if (individualDetails.thirdPageDetails) {
    const thirdPage = pdfDoc.getPage(2);

    const textWidth = font.widthOfTextAtSize(
      individualDetails.thirdPageDetails,
      12
    );
    const { width, height } = thirdPage.getSize();
    const centerX = (width - textWidth) / 2;
    thirdPage.drawText("testing", {
      x: centerX+87,
      y: height-82,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    thirdPage.drawText("test", {
      x: 209,
      y: height-131,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    thirdPage.drawText("test", {
      x: 265,
      y: height-131,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    thirdPage.drawText("test", {
      x: centerX+84,
      y: height-155,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    thirdPage.drawText("test", {
      x: centerX+145,
      y: height-155,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    thirdPage.drawText("test", {
      x: centerX+230,
      y: height-155,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    thirdPage.drawText("test", {
      x: centerX+63,
      y: height-168,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
   
    thirdPage.drawText("test", {
      x: 129,
      y: 223,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    thirdPage.drawText("test", {
      x: 190,
      y: 223,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    thirdPage.drawText("test", {
      x: 365,
      y: 223,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    thirdPage.drawText("test", {
      x: 433,
      y: 223,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    thirdPage.drawText("test", {
      x: 156,
      y: 211,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });

    thirdPage.drawText("test", {
      x: 228,
      y: 211,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
  }

  //page 12
  if (individualDetails.secondPageDetails) {
    const twelfthPage = pdfDoc.getPage(11);

    const textWidth = font.widthOfTextAtSize(
      individualDetails.secondPageDetails,
      12
    );
    const { width, height } = twelfthPage.getSize();
    const centerX = (width - textWidth) / 2;

    twelfthPage.drawText("test", {
      x: 66,
      y: height-100,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    twelfthPage.drawText("test", {
      x: width-190,
      y: height-100,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    
  }

  //page 14
  if (individualDetails.secondPageDetails) {
    const fourteenthPage = pdfDoc.getPage(13);

    const textWidth = font.widthOfTextAtSize(
      individualDetails.secondPageDetails,
      12
    );
    const { width, height } = fourteenthPage.getSize();
    const centerX = (width - textWidth) / 2;

    fourteenthPage.drawText("test", {
      x: width-160,
      y: height-130,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fourteenthPage.drawText("test", {
      x: centerX+23,
      y: height-240,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fourteenthPage.drawText("test", {
      x: centerX+10,
      y: height-301,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fourteenthPage.drawText("test", {
      x: centerX+72,
      y: height-411,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fourteenthPage.drawText("test", {
      x: centerX-44,
      y: height-459,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fourteenthPage.drawText("test", {
      x: centerX+68,
      y: height-483,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fourteenthPage.drawText("test", {
      x: centerX+135,
      y: height-483,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fourteenthPage.drawText("test", {
      x: centerX+205,
      y: height-483,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fourteenthPage.drawText("test", {
      x: centerX+2,
      y: height-495,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    
  }

  //page 15
  if (individualDetails.secondPageDetails) {
    const fifteenthPage = pdfDoc.getPage(14);

    const textWidth = font.widthOfTextAtSize(
      individualDetails.secondPageDetails,
      12
    );
    const { width, height } = fifteenthPage.getSize();
    const centerX = (width - textWidth) / 2;

    fifteenthPage.drawText("test", {
      x: width-190,
      y: height-94,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fifteenthPage.drawText("test", {
      x: 130,
      y: height-167,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    fifteenthPage.drawText("test", {
      x: 130,
      y: height-228,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    
  }

  //page 16
  if (individualDetails.secondPageDetails) {
    const sixteenthPage = pdfDoc.getPage(15);

    const textWidth = font.widthOfTextAtSize(
      individualDetails.secondPageDetails,
      12
    );
    const { width, height } = sixteenthPage.getSize();
    const centerX = (width - textWidth) / 2;

    sixteenthPage.drawText("test", {
      x: centerX+1,
      y: height-215,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    sixteenthPage.drawText("test", {
      x: 200,
      y: height-313,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    sixteenthPage.drawText("test", {
      x: 275,
      y: height-313,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    sixteenthPage.drawText("test", {
      x: 450,
      y: height-447,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    
    sixteenthPage.drawText("test", {
      x: 471,
      y: height-508,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    sixteenthPage.drawText("test", {
      x: 66,
      y: height-519,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    sixteenthPage.drawText("test", {
      x: 450,
      y: height-580,
      size: 12,
      font,
      color: rgb(0, 0, 0),
    });
    
  }


  //////////////////////////////////BondAbsa BondAbsa BondAbsa/////////////////////////////////////////////
  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default BondAbsa;
