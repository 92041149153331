import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import pdfDocument from "../Documents/Individual-IndividualTransfer.pdf";

const MortgageBondApplicationFnb = async (caseDetails) => {
  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(pdfDocument).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const pdfBytes = await fetchPdf();
  let details = await caseDetails?.transactionData?.formSchema.map(
    ({ name, value }) => {
      return { name, value };
    }
  );

  const flattenedObject = details?.reduce((acc, curr) => {
    acc[curr.name] = curr.value;
    return acc;
  }, {});

  details = {
    ...flattenedObject,
  };

  const pdfDoc = await PDFDocument.load(pdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const form = pdfDoc.getForm();

  const firstPage = pdfDoc.getPage(0);
  const secondPage = pdfDoc.getPage(1);
  const thirdPage = pdfDoc.getPage(2);
  const forthPage = pdfDoc.getPage(3);
  const fifthPage = pdfDoc.getPage(4);
  const sixPage = pdfDoc.getPage(5);
  const sevenPage = pdfDoc.getPage(6);
  const eightPage = pdfDoc.getPage(7);
  const ninePage = pdfDoc.getPage(8);
  const tenPage = pdfDoc.getPage(9);
  const elevenPage = pdfDoc.getPage(10);
  const twelvePage = pdfDoc.getPage(11);

  const { width, height } = firstPage.getSize();
  let textWidth = "";
  let centerX = "";

  const undersigned = form.createTextField("undersigned");
  undersigned.setText("Name of undersigned");
  undersigned.addToPage(firstPage, {
    x: 240,
    y: 628,
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const day_ = form.createTextField("day_");
  day_.setText("14");
  day_.addToPage(firstPage, {
    x: 210,
    y: 612,
    height: 17,
    width: 33,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const month_ = form.createTextField("month_");
  month_.setText("Decemeber");
  month_.addToPage(firstPage, {
    x: 269,
    y: 612,
    height: 17,
    width: 63,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const year_ = form.createTextField("year_");
  year_.setText("1994");
  year_.addToPage(firstPage, {
    x: 335,
    y: 612,
    height: 17,
    width: 46,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field5 = form.createTextField("field5");
  field5.setText("Samuel Modise");
  field5.addToPage(firstPage, {
    x: 230,
    y: 550, // Coordinate (282, 234)
    size: 12,
    font,
    color: rgb(0, 0, 0),
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field6_value = "Malebogo (Purchaser)";
  textWidth = font.widthOfTextAtSize(field6_value, 13);
  centerX = (width - textWidth) / 2 - 50;
  const field6 = form.createTextField("field6");
  field6.setText(field6_value);
  field6.addToPage(firstPage, {
    x: 230,
    y: 470, // Coordinate (282, 234)
    size: 13,
    font,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field7 = form.createTextField("field7");
  field7.setText("20");
  field7.addToPage(firstPage, {
    x: 204,
    y: 455, // Coordinate (282, 234)
    size: 12,
    font,
    width: 58,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field8 = form.createTextField("field8");
  field8.setText("December");
  field8.addToPage(firstPage, {
    x: 300,
    y: 455, // Coordinate (282, 234)
    size: 12,
    font,
    width: 70,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field9 = form.createTextField("field9");
  field9.setText("1990");
  field9.addToPage(firstPage, {
    x: 372,
    y: 455, // Coordinate (282, 234)
    size: 12,
    font,
    width: 47,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field10_value = "1990";
  textWidth = font.widthOfTextAtSize(field10_value, 11);
  centerX = (width - textWidth) / 2 - 50;
  const field10 = form.createTextField("field10");
  field10.setText(field10_value);
  field10.addToPage(firstPage, {
    x: centerX,
    y: 430, // Coordinate (282, 234)
    size: 11,
    font,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field11 = form.createTextField("field11");
  field11.setText("lot");
  field11.addToPage(firstPage, {
    x: 347,
    y: 321, // Coordinate (282, 234)
    size: 12,
    font,
    width: 95,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field12 = form.createTextField("field12");
  field12.setText("12345 Gaborone;");
  field12.addToPage(firstPage, {
    x: 213,
    y: 308, // Coordinate (282, 234)
    size: 13,
    font,
    width: 100,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field13 = form.createTextField("field13");
  field13.setText("lot");
  field13.addToPage(firstPage, {
    x: 213,
    y: 260, // Coordinate (282, 234)
    size: 12,
    font,
    width: 313,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field14 = form.createTextField("field14");
  field14.setText("transfer number");
  field14.addToPage(firstPage, {
    x: 356,
    y: 236, // Coordinate (282, 234)
    size: 12,
    font,
    width: 80,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field15 = form.createTextField("field15");
  field15.setText("16");
  field15.addToPage(firstPage, {
    x: 468,
    y: 236, // Coordinate (282, 234)
    size: 12,
    font,
    width: 24,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field16 = form.createTextField("field16");
  field16.setText("Decemeber 2024");
  field16.addToPage(firstPage, {
    x: 214,
    y: 223.48, // Coordinate (282, 234)
    size: 12,
    font,
    width: 66,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field17 = form.createTextField("field17");
  field17.setText("Masego Marang Modise");
  field17.addToPage(firstPage, {
    x: 370,
    y: 223.48, // Coordinate (282, 234)
    size: 12,
    font,
    width: 128,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field18 = form.createTextField("field18");
  field18.setText("14");
  field18.addToPage(secondPage, {
    x: 255,
    y: 723, // Coordinate (282, 234)
    size: 14,
    font,
    width: 70,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field19 = form.createTextField("field19");
  field19.setText("December 2024");
  field19.addToPage(secondPage, {
    x: 373,
    y: 723, // Coordinate (282, 234)
    size: 14,
    font,
    width: 140,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field20 = form.createTextField("field20");
  field20.setText("Witness 1");
  field20.addToPage(secondPage, {
    x: 90,
    y: 665, // Coordinate (282, 234)
    font,
    width: 140,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field21 = form.createTextField("field21");
  field21.setText("Witness 2");
  field21.addToPage(secondPage, {
    x: 90,
    y: 630, // Coordinate (282, 234)
    size: 14,
    font,
    width: 140,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field22 = form.createTextField("field22");
  field22.setText("signature?");
  field22.addToPage(secondPage, {
    x: 380,
    y: 641, // Coordinate (282, 234)
    size: 14,
    font,
    width: 140,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  // PAGE 3

  const undersignedseller = form.createTextField("undersignedseller");
  undersignedseller.setText("Name of undersigned");
  undersignedseller.addToPage(thirdPage, {
    x: 238,
    y: 725,
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const day__ = form.createTextField("day__");
  day__.setText("14");
  day__.addToPage(thirdPage, {
    x: 210,
    y: 709,
    height: 17,
    width: 33,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const month__ = form.createTextField("month__");
  month__.setText("Decemeber");
  month__.addToPage(thirdPage, {
    x: 269,
    y: 709,
    height: 17,
    width: 63,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const year__ = form.createTextField("year__");
  year__.setText("1994");
  year__.addToPage(thirdPage, {
    x: 335,
    y: 709,
    height: 17,
    width: 46,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field23 = form.createTextField("field23");
  field23.setText("Samuel Modise");
  field23.addToPage(thirdPage, {
    x: 200,
    y: 615, // Coordinate (282, 234)
    size: 12,
    font,
    color: rgb(0, 0, 0),
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field241 = "4";
  textWidth = font.widthOfTextAtSize(field241, 13);
  centerX = (width - textWidth) / 2 - 50;
  const field24 = form.createTextField("field24");
  field24.setText(field241);
  field24.addToPage(thirdPage, {
    x: 254,
    y: 601, // Coordinate (282, 234)
    size: 13,
    font,
    width: 14,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field25 = form.createTextField("field25");
  field25.setText("November 1985");
  field25.addToPage(thirdPage, {
    x: 305,
    y: 601, // Coordinate (282, 234)
    size: 12,
    font,
    width: 100,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field26 = form.createTextField("field26");
  field26.setText("Micheal Smith");
  field26.addToPage(thirdPage, {
    x: 205,
    y: 576, // Coordinate (282, 234)
    size: 12,
    font,

    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field27 = form.createTextField("field27");
  field27.setText("lot");
  field27.addToPage(thirdPage, {
    x: 347,
    y: 515.5, // Coordinate (282, 234)
    size: 12,
    font,
    width: 95,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field28 = form.createTextField("field28");
  field28.setText("247548 Gaborone");
  field28.addToPage(thirdPage, {
    x: 213,
    y: 500, // Coordinate (282, 234)
    size: 12,
    font,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field29 = form.createTextField("field29");
  field29.setText("measuring");
  field29.addToPage(thirdPage, {
    x: 213,
    y: 456, // Coordinate (282, 234)
    size: 12,
    font,
    width: 313,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field30 = form.createTextField("field30");
  field30.setText("transfer number");
  field30.addToPage(thirdPage, {
    x: 356,
    y: 430.5, // Coordinate (282, 234)
    size: 12,
    font,
    width: 80,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field31 = form.createTextField("field31");
  field31.setText("16");
  field31.addToPage(thirdPage, {
    x: 468,
    y: 430.5, // Coordinate (282, 234)
    size: 12,
    font,
    width: 35,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field32 = form.createTextField("field32");
  field32.setText("Decemeber 2024");
  field32.addToPage(thirdPage, {
    x: 214,
    y: 418.3, // Coordinate (282, 234)
    size: 12,
    font,
    width: 105,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field33 = form.createTextField("field33");
  field33.setText("Masego Marang Modise");
  field33.addToPage(thirdPage, {
    x: 411,
    y: 418.3, // Coordinate (282, 234)
    size: 12,
    font,
    width: 128,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field34 = form.createTextField("field34");
  field34.setText("13");
  field34.addToPage(thirdPage, {
    x: 396,
    y: 394, // Coordinate (282, 234)
    size: 12,
    font,
    width: 40,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field35 = form.createTextField("field35");
  field35.setText("13");
  field35.addToPage(thirdPage, {
    x: 472,
    y: 394, // Coordinate (282, 234)
    size: 12,
    font,
    width: 60,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field36 = form.createTextField("field36");
  field36.setText("13");
  field36.addToPage(thirdPage, {
    x: 68,
    y: 382, // Coordinate (282, 234)
    size: 12,
    font,
    width: 63,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field37 = form.createTextField("field37");
  field37.setText("14");
  field37.addToPage(thirdPage, {
    x: 84,
    y: 345.6, // Coordinate (282, 234)
    size: 18,
    font,
    width: 400,
    color: rgb(0, 0, 0),
    height: 14.5,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field38 = form.createTextField("field38");
  field38.setText("14");
  field38.addToPage(forthPage, {
    x: 390,
    y: 375, // Coordinate (282, 234)
    size: 18,
    font,
    width: 120,
    color: rgb(0, 0, 0),
    height: 14.5,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field39 = form.createTextField("field39");
  field39.setText("date");
  field39.addToPage(forthPage, {
    x: 338,
    y: 297, // Coordinate (282, 234)
    size: 18,
    font,
    width: 40,
    color: rgb(0, 0, 0),
    height: 14.5,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field40 = form.createTextField("field40");
  field40.setText("14");
  field40.addToPage(forthPage, {
    x: 425,
    y: 297, // Coordinate (282, 234)
    size: 18,
    font,
    width: 100,
    color: rgb(0, 0, 0),
    height: 14.5,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field42 = form.createTextField("field42");
  field42.setText("14");
  field42.addToPage(fifthPage, {
    x: 250,
    y: 600, // Coordinate (282, 234)
    size: 18,
    font,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field43 = form.createTextField("field43");
  field43.setText("14");
  field43.addToPage(fifthPage, {
    x: 200,
    y: 503.4, // Coordinate (282, 234)
    size: 18,
    font,
    width: 30,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field44 = form.createTextField("field44");
  field44.setText("14");
  field44.addToPage(fifthPage, {
    x: 265,
    y: 503.4, // Coordinate (282, 234)
    size: 18,
    font,
    width: 60,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field45 = form.createTextField("field45");
  field45.setText("Barchelor");
  field45.addToPage(fifthPage, {
    x: 142,
    y: 466.6, // Coordinate (282, 234)
    size: 18,
    font,
    width: 60,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field46 = form.createTextField("field46");
  field46.setText("10th");
  field46.addToPage(fifthPage, {
    x: 458,
    y: 321, // Coordinate (282, 234)
    size: 18,
    font,
    width: 40,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field47 = form.createTextField("field47");
  field47.setText("10th");
  field47.addToPage(fifthPage, {
    x: 88,
    y: 309, // Coordinate (282, 234)
    size: 18,
    font,
    width: 75,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field48 = form.createTextField("field48");
  field48.setText("Name of field48");
  field48.addToPage(sixPage, {
    x: 240,
    y: 659,
    height: 17,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field49 = form.createTextField("field49");
  field49.setText("14");
  field49.addToPage(sixPage, {
    x: 255,
    y: 647,
    height: 14,
    width: 33,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field50 = form.createTextField("field50");
  field50.setText("Decemeber");
  field50.addToPage(sixPage, {
    x: 320,
    y: 647,
    height: 15,
    width: 60,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field51 = form.createTextField("field51");
  field51.setText("Decemeber");
  field51.addToPage(sixPage, {
    x: 270,
    y: 622,
    height: 15,
    width: 100,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field52 = form.createTextField("field52");
  field52.setText("12th");
  field52.addToPage(sixPage, {
    x: 420,
    y: 622,
    height: 15,
    width: 35,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field53 = form.createTextField("field53");
  field53.setText("12th");
  field53.addToPage(sixPage, {
    x: 493,
    y: 622,
    height: 15,
    width: 35,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field54 = form.createTextField("field54");
  field54.setText("12th");
  field54.addToPage(sixPage, {
    x: 70,
    y: 611,
    height: 15,
    width: 35,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field55 = form.createTextField("field55 ");
  field55.setText("12th");
  field55.addToPage(sixPage, {
    x: 70,
    y: 611,
    height: 15,
    width: 35,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field56 = form.createTextField("field56 ");
  field56.setText("Thabang Peabo");
  field56.addToPage(sixPage, {
    x: 310,
    y: 610.3,
    height: 15,
    width: 153,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field57 = form.createTextField("field57");
  field57.setText("Thabang Peabo");
  field57.addToPage(sixPage, {
    x: 145,
    y:598,
    height: 14,
    width: 82,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field58 = form.createTextField("field58");
  field58.setText("14th");
  field58.addToPage(sixPage, {
    x: 285,
    y:598,
    height: 15,
    width:40,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field59 = form.createTextField("field59");
  field59.setText("14th");
  field59.addToPage(sixPage, {
    x: 370,
    y:598,
    height: 15,
    width:134,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field60 = form.createTextField("field60");
  field60.setText("14th");
  field60.addToPage(sixPage, {
    x: 345,
    y:549,
    height: 15,
    width:100,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field61 = form.createTextField("field61");
  field61.setText("14th");
  field61.addToPage(sixPage, {
    x: 211,
    y:536,
    height: 15,
    width:115,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field62 = form.createTextField("field62");
  field62.setText("14th");
  field62.addToPage(sixPage, {
    x: 211,
    y:489,
    height: 15,
    width:300,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });


  const field63 = form.createTextField("field63");
  field63.setText("transfer number");
  field63.addToPage(sixPage, {
    x: 356,
    y: 465, // Coordinate (282, 234)
    size: 12,
    font,
    width:70,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field64 = form.createTextField("field64");
  field64.setText("16");
  field64.addToPage(sixPage, {
    x: 460,
    y: 464.5, // Coordinate (282, 234)
    size: 12,
    font,
    width: 30,
    color: rgb(0, 0, 0),
    height: 14,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field65 = form.createTextField("field65");
  field65.setText("Decemeber 2024");
  field65.addToPage(sixPage, {
    x: 214,
    y: 452,  // Coordinate (282, 234)
    size: 12,
    font,
    width: 100,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  const field66 = form.createTextField("field66");
  field66.setText("Name");
  field66.addToPage(sixPage, {
    x: 378,
    y: 452,  // Coordinate (282, 234)
    size: 12,
    font,
    width: 105,
    color: rgb(0, 0, 0),
    height: 15,
    borderColor: rgb(1, 1, 1),
    backgroundColor: rgb(1, 1, 1),
  });

  // const field20 = form.createTextField("field20");
  // field20.setText("Witness 1");
  // field20.addToPage(secondPage, {
  //   x: 90,
  //   y: 665, // Coordinate (282, 234)
  //   font,
  //   width: 140,
  //   color: rgb(0, 0, 0),
  //   height: 15,
  //   borderColor: rgb(1, 1, 1),
  //   backgroundColor: rgb(1, 1, 1),
  // });

  // const field21 = form.createTextField("field21");
  // field21.setText("Witness 2");
  // field21.addToPage(secondPage, {
  //   x: 90,
  //   y: 630, // Coordinate (282, 234)
  //   size: 14,
  //   font,
  //   width: 140,
  //   color: rgb(0, 0, 0),
  //   height: 14,
  //   borderColor: rgb(1, 1, 1),
  //   backgroundColor: rgb(1, 1, 1),
  // });

  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default MortgageBondApplicationFnb;
