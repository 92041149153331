import React, { useEffect, useState } from "react";
import CurrentCases from "../../components/CurrentCases";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQueryParams } from "../../hooks/useQueryParams";
import { BiChevronDown } from "react-icons/bi";
import { BiAlarm } from "react-icons/bi";
import { PiSuitcaseSimple } from "react-icons/pi";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import KeyDates from "../../components/KeyDates";


function Cases() {
  const query = useQueryParams();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [tab, setTab] = React.useState(query.get("tab") || "current-cases");
  const history = useHistory();
  useEffect(() => {
    setTab(query.get("tab") || "current-cases");
  }, [query.get("tab")]);

  const saveCategories = (data) => {
    setCategories(data);
  };

  return (
    <>
      {/* Open the modal using document.getElementById('ID').showModal() method */}

      <div className="flex  items-center justify-between pt-12 px-8 ">
        <div role="tablist" className="w-5/12 tabs tabs-bordered">
          <button
            onClick={() => history.replace("/app/cases?tab=current-cases")}
            role="tab"
            className={`tab uppercase transition-all ${
              tab === "current-cases" && "tab-active text-white font-medium"
            }`}
            style={{ borderColor: tab == "current-cases" ? "#0e4d85" : "" }}
          >
            Current cases
          </button>
          <button
            onClick={() => history.replace(`/app/cases?tab=key-dates`)}
            className={`tab uppercase ${
              tab === "key-dates" && "tab-active  text-white font-medium"
            } text-gray-700`}
            disabled
            style={{ borderColor: tab == "key-dates" ? "#0e4d85" : "" }}
            role="tab"
          >
            Key dates
          </button>
        </div>
        <div>
          <div className="">
            <div className="flex gap-3 items-center">
              <div className="cursor-pointer">
                <a  onClick={()=>{
                  history.push("cases_list")
                }}className="rounded-[100px] px-7 py-3 text-white border-gray border text-sm bg-[#0e4d85]">
                  Change view
                </a>
              </div>
              <div>
                <div className=" w-[200px] flex rounded-[100px] justify-center items-center gap-2   px-7 py-2 text-white border-gray border text-sm bg-[#102232]">
                 <select
                    className={`${categories.length === 0 ? "flex" : "hidden"} text-[#a6adbb] py-[3px] bg-[#102232] w-full outline-none`}
                    onChange={(event) => setSelectedOption(event.target.value)}
                  >
                    <option value="Tasks">Tasks</option>
                    <option value="Transactions">Transactions</option>
                    <option value="Bank">Bank</option>
                  </select>
                  <div
                    className={`${categories.length === 0 ? "hidden" : "flex"}`}
                  >
                    <select
                    className="bg-transparent outline-none w-full focus-outline-none py-[3px]"
                      onChange={(e) => {
                        setCategory(e.target.value);
                        if (e.target.value === "Change filter") {
                          setSelectedOption("");
                          setCategory("");
                        }
                      }}
                    >
                   
                      {["Change filter", ...categories].map((item) => (
                        <option className="bg-[#102232] h-[40px] " value={item}>{item}</option>
                      ))}

                   
                     
                    </select>
                  </div>
                </div>
                {/* <Dropdown/> */}
              </div>
              <div>
                <input
                onChange={(e)=> setCategory(e.target.value)}
                  placeholder="Search"
                  className="bg-[#102232] text-[#a6adbb] outline-none border-gray border pl-4  pr-8 py-[7px] rounded-[100px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {tab === "current-cases" && (
        <CurrentCases
          selectedOption={selectedOption}
          saveCategories={saveCategories}
          categories={categories}
          category={category}
        />
      )}
      {tab === "key-dates" && <KeyDates />}
    </>
  );
}

// A custom hook that builds on useLocation to parse
// the query string for you.

export default Cases;
export const Modal = () => {
  return (
    <>
      <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle ">
        <div className="modal-box  overflow-visible bg-[#102232]">
          <h3 className="font-bold  text-lg"> Select transaction type !</h3>

          <div className="w-full bg-[#071b2e] mt-6 rounded-xl  border-[.5px]">
            <div className="dropdown w-full dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className=" min-h-12 gap-5  pl-5 items-center flex text-start btn-ghost rounded-btn w-full"
              >
                <PiSuitcaseSimple />
                Dropdown
              </div>
              <ul
                tabIndex={0}
                className="menu dropdown-content border bg-[#102232] z-[1] p-2 w-full shadow bg-bae-100 rounded-box mt-4"
              >
                <div className="p-4">Select an item</div>
                <div className="divider  border-white p-0 m-0"></div>
                <li className="b">
                  <Link to="/onboard">1. Individual-to-indiviadual</Link>
                  <Link to="/onboard">2. Individual-to-estate</Link>
                  <Link to="/onboard">3. Company-to-company</Link>
                  <Link to="/onboard">4. Individual-to-company</Link>
                  <Link to="/onboard">5. Society/Trust/Association</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-action">
            <form method="dialog">
              <button className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-0 text-white p-3 hover:scale-105 duration-300 h-[35px]">
                Close
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
};
