import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../images/logo.png";
import { IoMdArrowBack } from "react-icons/io";

const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
function SelectTemplate() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const history = useHistory();

  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex px-20 flex-row mb-4 container mx-auto text-xl items-center">
        <span
          aria-hidden="true"
          className="cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          {<IoMdArrowBack />}
        </span>
        <label className="w-full text-xl flex justify-center font-medium">
          <span className="font-light text-white mr-1">
            Create or select a transaction{" "}
          </span>
          template
        </label>
      </div>

      <div className="container mx-auto w-[530px] text-center  text-[#a6adbb] mt-10">
        Please construct the forms as accurately as possible. Remember that the
        form can also be edited at a later date. These forms can be accessed by
        clicking on the ‘Open Saved Forms’ button
      </div>

      <div className="border-b bg-red border-gray-900/10 mb-10 w-screen  mt-10 text-[#a6adbb]">
        <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  p-10 shadow-lg  border border-[#585d61]">
          <Link to="/templates/saved-templates">
            <div className="bg-[#081C2F]  border text-[#a6adbb]  border-[#585d61] h-[100px] flex justify-center items-center rounded-3xl">
              View transaction templates
            </div>
          </Link>
          <Link to="new">
            <div className="bg-[#2d2b77] border text-[#a6adbb]  border-[#2d2b77] h-[100px] flex justify-center items-center rounded-3xl">
              Create new transaction
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SelectTemplate;
