import React, { useState, useEffect } from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import kycPdfUrl from "./../../Documents/KYC.pdf"; // Adjust the path to your PDF file
import non_individuals_PdfUrl from "./../../Documents/Kyc_non_individuals.pdf"; // Adjust the path to your PDF file
import Individual_Kyc_Template from "../../PDFComponents/Kyc";
import Company_Kyc_Template from "../../PDFComponents/Kyc_non_individuals";
import { processPreviewTransaction } from './TransactionProcessor';

const PreviewCase = ({ caseData }) => {

console.log({caseData})


  useEffect(() => {
    handleModifyAndCombinePdfs();
  }, []);



  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(kycPdfUrl).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const fetchPdf_non_individuals = async () => {
    const existingPdfBytes = await fetch(non_individuals_PdfUrl).then((res) =>
      res.arrayBuffer()
    );
    return existingPdfBytes;
  };

  const combinePdfs = async (pdfs) => {
    const combinedPdf = await PDFDocument.create();

    for (const pdfBytes of pdfs) {
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await combinedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
      );
      copiedPages.forEach((page) => combinedPdf.addPage(page));
    }

    const combinedPdfBytes = await combinedPdf.save();
    return combinedPdfBytes;
  };



 const handleKYC = async()=>{
    // KYC HANDLING METHOD START
    const kycData = caseData.kyc;
    let company_Kyc_Array = [];
    let individual_Kyc_Array = [];

    kycData.forEach((kycEntry, index) => {
      if (kycEntry.formName.toLowerCase().includes("individual")) {
        // Generate the individual KYC object dynamically
        let kycDetails = caseData.kyc
          .filter((object) =>
            object.formName.toLowerCase().includes("individual")
          )
          .map(({ formSchema }) => {
            return formSchema.map(({ name, value }) => {
              return { name, value };
            });
          });

        const flattenedObject = kycDetails[0].reduce((acc, curr) => {
          acc[curr.name] = curr.value;
          return acc;
        }, {});

        kycDetails = {
          ...flattenedObject,
          firstPageDetails: `John Doe ${index + 1}`,
          secondPageDetails: `Software Engineer ${index + 1}`,
          thirdPageDetails: `Department: Engineering ${index + 1}`,
        };

        // Push this individual KYC into the result array
        individual_Kyc_Array.push(kycDetails);

        console.log({individual_Kyc_Array})
      }

      if (kycEntry.formName.toLowerCase().includes("company")) {
        // Generate the individual KYC object dynamically
        let kycDetails = caseData.kyc
          .filter((object) => object.formName.toLowerCase().includes("company"))
          .map(({ formSchema }) => {
            return formSchema.map(({ name, value }) => {
              return { name, value };
            });
          });

        const flattenedObject = kycDetails[0].reduce((acc, curr) => {
          acc[curr.name] = curr.value;
          return acc;
        }, {});

        kycDetails = {
          ...flattenedObject,
          firstPageDetails: `John Doe ${index + 1}`,
          secondPageDetails: `Software Engineer ${index + 1}`,
          thirdPageDetails: `Department: Engineering ${index + 1}`,
        };

        // Push this individual KYC into the result array
        company_Kyc_Array.push(kycDetails);
      }
    });

    const indvidual_kycpdfBytes = await fetchPdf();
    const company_kycpdfBytes = await fetchPdf_non_individuals();

    const kycData_ = [];
    for (let index in individual_Kyc_Array) {
      let kycBytes = {}; // Create an object to hold dynamic keys
      kycBytes[`individual_kyc${parseInt(index) + 1}_Bytes`] =
        await Individual_Kyc_Template(
          indvidual_kycpdfBytes,
          individual_Kyc_Array[index]
        );
      kycData_.push(kycBytes[`individual_kyc${parseInt(index) + 1}_Bytes`]);
    }

    for (let index in company_Kyc_Array) {
      let kycBytes = {}; // Create an object to hold dynamic keys
      kycBytes[`company_kyc${parseInt(index) + 1}_Bytes`] =
        await Company_Kyc_Template(
          company_kycpdfBytes,
          individual_Kyc_Array[index]
        );
      kycData_.push(kycBytes[`company_kyc${parseInt(index) + 1}_Bytes`]);
    }

   return kycData_ ;
    // KYC HANDLING METHOD END
 }


 

  const handleModifyAndCombinePdfs = async () => {
  
    // kyc handling method
    const _kycData =  await handleKYC() ;
  
   const method = await processPreviewTransaction(caseData);
    

    // Combine the modified PDFs
    const combinedPdfBytes = await combinePdfs(
      [method]
      // [..._kycData].concat(method)
    );

    // Create a URL for the combined PDF
    const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    // Open the combined PDF in a new tab
    window.open(url, "_blank");
  };
  return null; // The component doesn't need to render anything in the DOM
};

export default PreviewCase;



// import React, { useState, useEffect } from "react";
// import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import kycPdfUrl from "./../../Documents/KYC.pdf"; // Adjust the path to your PDF file
// import non_individuals_PdfUrl from "./../../Documents/Kyc_non_individuals.pdf"; // Adjust the path to your PDF file
// import bondAbsaPdfUrl from "./../../Documents/BondAbsa.pdf"; // Adjust the path to your PDF file
// import BondAbsa from "../../PDFComponents/BondAbsa";
// import Individual_Kyc_Template from "../../PDFComponents/Kyc";
// import Company_Kyc_Template from "../../PDFComponents/Kyc_non_individuals";

// const PreviewCase = ({ caseData }) => {

// console.log({caseData})


//   useEffect(() => {
//     handleModifyAndCombinePdfs();
//   }, []);
//   const fetchPdf = async () => {
//     const existingPdfBytes = await fetch(kycPdfUrl).then((res) =>
//       res.arrayBuffer()
//     );
//     return existingPdfBytes;
//   };
//   const fetchPdf_non_individuals = async () => {
//     const existingPdfBytes = await fetch(non_individuals_PdfUrl).then((res) =>
//       res.arrayBuffer()
//     );
//     return existingPdfBytes;
//   };
//   const fetchBondAbsaPdf = async () => {
//     const existingPdfBytes = await fetch(bondAbsaPdfUrl).then((res) =>
//       res.arrayBuffer()
//     );
//     return existingPdfBytes;
//   };



//   const combinePdfs = async (pdfs) => {
//     const combinedPdf = await PDFDocument.create();

//     for (const pdfBytes of pdfs) {
//       const pdfDoc = await PDFDocument.load(pdfBytes);
//       const copiedPages = await combinedPdf.copyPages(
//         pdfDoc,
//         pdfDoc.getPageIndices()
//       );
//       copiedPages.forEach((page) => combinedPdf.addPage(page));
//     }

//     const combinedPdfBytes = await combinedPdf.save();
//     return combinedPdfBytes;
//   };



//  const handleKYC = async()=>{
//     // KYC HANDLING METHOD START
//     const kycData = caseData.kyc;
//     let company_Kyc_Array = [];
//     let individual_Kyc_Array = [];

//     kycData.forEach((kycEntry, index) => {
//       if (kycEntry.formName.toLowerCase().includes("individual")) {
//         // Generate the individual KYC object dynamically
//         let kycDetails = caseData.kyc
//           .filter((object) =>
//             object.formName.toLowerCase().includes("individual")
//           )
//           .map(({ formSchema }) => {
//             return formSchema.map(({ name, value }) => {
//               return { name, value };
//             });
//           });

//         const flattenedObject = kycDetails[0].reduce((acc, curr) => {
//           acc[curr.name] = curr.value;
//           return acc;
//         }, {});

//         kycDetails = {
//           ...flattenedObject,
//           firstPageDetails: `John Doe ${index + 1}`,
//           secondPageDetails: `Software Engineer ${index + 1}`,
//           thirdPageDetails: `Department: Engineering ${index + 1}`,
//         };

//         // Push this individual KYC into the result array
//         individual_Kyc_Array.push(kycDetails);
//       }

//       if (kycEntry.formName.toLowerCase().includes("company")) {
//         // Generate the individual KYC object dynamically
//         let kycDetails = caseData.kyc
//           .filter((object) => object.formName.toLowerCase().includes("company"))
//           .map(({ formSchema }) => {
//             return formSchema.map(({ name, value }) => {
//               return { name, value };
//             });
//           });

//         const flattenedObject = kycDetails[0].reduce((acc, curr) => {
//           acc[curr.name] = curr.value;
//           return acc;
//         }, {});

//         kycDetails = {
//           ...flattenedObject,
//           firstPageDetails: `John Doe ${index + 1}`,
//           secondPageDetails: `Software Engineer ${index + 1}`,
//           thirdPageDetails: `Department: Engineering ${index + 1}`,
//         };

//         // Push this individual KYC into the result array
//         company_Kyc_Array.push(kycDetails);
//       }
//     });

//     const indvidual_kycpdfBytes = await fetchPdf();
//     const company_kycpdfBytes = await fetchPdf_non_individuals();

//     const kycData_ = [];
//     for (let index in individual_Kyc_Array) {
//       let kycBytes = {}; // Create an object to hold dynamic keys
//       kycBytes[`individual_kyc${parseInt(index) + 1}_Bytes`] =
//         await Individual_Kyc_Template(
//           indvidual_kycpdfBytes,
//           individual_Kyc_Array[index]
//         );
//       kycData_.push(kycBytes[`individual_kyc${parseInt(index) + 1}_Bytes`]);
//     }

//     for (let index in company_Kyc_Array) {
//       let kycBytes = {}; // Create an object to hold dynamic keys
//       kycBytes[`company_kyc${parseInt(index) + 1}_Bytes`] =
//         await Company_Kyc_Template(
//           company_kycpdfBytes,
//           individual_Kyc_Array[index]
//         );
//       kycData_.push(kycBytes[`company_kyc${parseInt(index) + 1}_Bytes`]);
//     }

//    return kycData_ ;
//     // KYC HANDLING METHOD END
//  }


//  const handleBonds = async()=>{
//  const bondAbsapdfBytes = await fetchBondAbsaPdf();
//   let transactionDetails = await caseData?.transactionData?.formSchema.map(({ name, value }) => {
//         return { name, value };
//     });
//   const flattenedObject = transactionDetails.reduce((acc, curr) => {
//     acc[curr.name] = curr.value;
//     return acc;
//   }, {});
//   transactionDetails = {
//     ...flattenedObject,
//     firstPageDetails: `John Doe `,
//     secondPageDetails: `Software Engineer`,
//     thirdPageDetails: `Department: Engineering `,
//   };
// // console.log({transactionDetails})
//     return  await BondAbsa(bondAbsapdfBytes, transactionDetails);
//   }


//   const handleModifyAndCombinePdfs = async () => {
  
//     // kyc handling method
//     const _kycData =  await handleKYC() ;
  


//     let transactionType = caseData?.collectionName?.toString().trim() || "Unknown";

//     let method;
//     console.log({transactionType});
//     switch (transactionType) {
//         case 'Cancellation of Mortgage  Bond':
//             method = await handleBonds();
//             break;
//         case 'Cancellation of Lease':
//             method = "Monday";
//             break;
//         case 'Application for Subdivision':
//             method = "Tuesday";
//             break;
//         case 'Opening of the Sectional Scheme':
//             method = "Wednesday";
//             break;
//         case 'Application for Consolidation':
//             method = "Thursday";
//             break;
//         case 'Registration of General Plan':
//             method = "Friday";
//             break;
//         case 'Sectional Bonds':
//             method = "Saturday";
//             break;
//         default:
//             method = "Invalid transaction type";
//     }
    

    

//     // Combine the modified PDFs
//     const combinedPdfBytes = await combinePdfs(
//       [..._kycData].concat(method)
//     );

//     // Create a URL for the combined PDF
//     const blob = new Blob([combinedPdfBytes], { type: "application/pdf" });
//     const url = URL.createObjectURL(blob);

//     // Open the combined PDF in a new tab
//     window.open(url, "_blank");
//   };
//   return null; // The component doesn't need to render anything in the DOM
// };

// export default PreviewCase;
