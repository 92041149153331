import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

const Kyc = async (pdfBytes, individualDetails) => {
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  // Modify each page with individual details
  //////////////////////////////////KYC KYC KYC//////////////////////////////////////////////
  // if (individualDetails.firstPageDetails) {
  const firstPage = pdfDoc.getPage(0);
  firstPage.drawText(individualDetails?.partyName_1 || "", {
    x: 103,
    y: 546,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.firstPageDetails || "", {
    x: 103 + 25,
    y: 546 - 20,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.firstPageDetails || "", {
    x: 103 + 15,
    y: 546 - 40,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.date_of_birth || "DOB", {
    x: 103 + 45,
    y: 546 - 59,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.nationality || "Nationality", {
    x: 103 + 40,
    y: 546 - 78,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.national_id_number || "ID number", {
    x: 103 + 45,
    y: 546 - 98,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.marital_status || "Marital", {
    x: 103 + 30,
    y: 546 - 118,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.postal_address || "Postal Address", {
    x: 103 + 55,
    y: 546 - 198,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.physical_address || "Physical", {
    x: 103 + 70,
    y: 546 - 218,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.village_ward || "Ward", {
    x: 103 + 70,
    y: 546 - 236,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.town_city || "City", {
    x: 103 + 70,
    y: 546 - 258,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.country || "Country", {
    x: 103 + 70,
    y: 546 - 278,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.duration_of_stay || "Duration", {
    x: 103 + 70,
    y: 546 - 298,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.fax || "Fax", {
    x: 103 + 265,
    y: 546 - 319,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  firstPage.drawText(individualDetails?.mobile_number || "Mobile", {
    x: 103 + 75,
    y: 546 - 318,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.email || "Email", {
    x: 103 + 75,
    y: 546 - 339,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.employer || "Employer", {
    x: 103 + 75,
    y: 546 - 358,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.occupation || "Occupation", {
    x: 103 + 75,
    y: 546 - 379,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.work_number || "Work Number", {
    x: 103 + 110,
    y: 546 - 400,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.fax || "Fax", {
    x: 103 + 260,
    y: 546 - 400,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  firstPage.drawText(individualDetails?.place_of_work || "Place of work", {
    x: 103 + 315,
    y: 546 - 359,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  // }
  // if (individualDetails.secondPageDetails) {
  const secondPage = pdfDoc.getPage(1);
  

  // checkbox
  secondPage.drawText("x", {
    x: 214,
    y: 626,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText("x", {
    x: 382,
    y: 626,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText("x", {
    x: 450,
    y: 626,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText("x", {
    x: 486,
    y: 605,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  secondPage.drawText("x", {
    x: 378,
    y: 605,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });


  secondPage.drawText("x", {
    x: 177,
    y: 605,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  // checkbox



  secondPage.drawText(individualDetails?.bank_name || 'N/A', {
    x: 142,
    y: 536,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText(individualDetails?.account_name || 'N/A', {
    x: 363,
    y: 536,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText(individualDetails?.branch_name || 'N/A', {
    x: 363,
    y: 515.5,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText(individualDetails?.account_number || 'N/A', {
    x: 170,
    y: 515.5,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText(individualDetails?.bank_account || 'N/A', {
    x: 140,
    y: 495,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText(individualDetails?.source_of_funds || 'N/A', {
    x: 168,
    y: 428.8,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText(individualDetails?.purpose_of_funds || 'N/A', {
    x: 175,
    y: 409,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  // this part here is for the documents submitted
  secondPage.drawText("x", {
    x:75,
    y: 302,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  secondPage.drawText("x", {
    x:75,
    y: 286,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText("x", {
    x:75,
    y: 270,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText("x", {
    x:75,
    y: 250,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText("x", {
    x:75,
    y: 234,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  secondPage.drawText("x", {
    x:75,
    y: 217,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });
  // this part here is for the documents submitted
  // }
  // if (individualDetails.thirdPageDetails) {
  const thirdPage = pdfDoc.getPage(2);
  thirdPage.drawText(individualDetails?.partyName_1 || "N/A", {
    x: 130,
    y: 439,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText(individualDetails?.signature || "N/A", {
    x: 110,
    y: 412,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  thirdPage.drawText(individualDetails?.date || 'N/A', {
    x: 100,
    y: 385.5,
    size: 12,
    font,
    color: rgb(0, 0, 0),
  });

  // }
  //////////////////////////////////KYC KYC KYC/////////////////////////////////////////////
  const modifiedPdfBytes = await pdfDoc.save();
  return modifiedPdfBytes;
};

export default Kyc;
